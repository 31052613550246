.analytics {
  padding: 2rem;
  color: #E4E6EB;
}

.analytics-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.analytics-controls {
  display: flex;
  gap: 12px;
}

.time-range-select {
  padding: 8px 16px;
  border-radius: 8px;
  background: var(--glass-bg);
  border: 1px solid var(--glass-border);
  color: var(--discord-light);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.time-range-select:hover {
  border-color: var(--accent-purple);
  background: rgba(155, 132, 236, 0.1);
}

.analytics-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 1.5rem;
  transition: transform 0.2s ease;
  min-height: 0;
}

.analytics-card.wide {
  grid-column: span 2;
}

.analytics-card.full-width {
  grid-column: 1 / -1;
}

.analytics-card:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.08);
}

.analytics-card.half-height {
  min-height: 220px;
}

.analytics-card.stacked {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0;
  background: none;
}

.analytics-card.stacked .analytics-card {
  margin: 0;
  height: calc((100% - 1.5rem) / 2);
}

.analytics-card.stacked .analytics-card.half-height {
  height: calc((100% - 1.5rem) / 2);
  min-height: 220px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 0.5rem;
}

.card-header h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--discord-light);
}

.card-actions {
  display: flex;
  gap: 12px;
  align-items: center;
}

.trend-indicator {
  font-size: 14px;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 12px;
  background: rgba(59, 165, 92, 0.1);
}

.trend-indicator.positive {
  color: var(--success-green);
}

.trend-indicator.negative {
  color: var(--error-red);
  background: rgba(237, 66, 69, 0.1);
}

.overview-grid {
  display: flex;
  gap: 20px;
  padding: 0.5rem;
  position: relative;
  margin-top: 0.5rem;
}

.overview-grid-group {
  display: flex;
  gap: 8px;
}

.overview-grid-group.historical {
  width: calc(33% - 10px);
}

.overview-grid-group.dynamic {
  width: calc(66% - 10px);
}

.overview-stat {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: center;
  position: relative;
  padding: 0.5rem;
  flex: 1;
  transition: all 0.2s ease;
  z-index: 1;
}

/* Historical stats container */
.overview-grid::before {
  content: '';
  position: absolute;
  left: 0.5rem;
  top: 0.25rem;
  width: calc(33% - 15px);
  height: calc(100% - 0.5rem);
  background: var(--glass-bg);
  border: 1px solid var(--glass-border);
  border-radius: 8px;
  z-index: 0;
  transition: all 0.2s ease;
  opacity: 0.5;
}

/* Dynamic stats container */
.overview-grid::after {
  content: '';
  position: absolute;
  left: calc(33% + 10px);
  top: 0.25rem;
  width: calc(66% - 15px);
  height: calc(100% - 0.5rem);
  background: rgba(155, 132, 236, 0.02);
  border: 1px solid rgba(155, 132, 236, 0.1);
  border-radius: 8px;
  z-index: 0;
  transition: all 0.2s ease;
  opacity: 0.3;
}

/* Historical label */
.overview-grid .historical-label {
  position: absolute;
  top: -0.25rem;
  left: 1rem;
  padding: 0.1rem 0.4rem;
  background: var(--glass-bg);
  border: 1px solid var(--glass-border);
  border-radius: 6px;
  font-size: 0.65rem;
  color: var(--accent-purple);
  z-index: 2;
  opacity: 0.8;
}

/* Dynamic label */
.overview-grid .dynamic-label {
  position: absolute;
  top: -0.25rem;
  left: calc(33% + 15px);
  padding: 0.1rem 0.4rem;
  background: rgba(155, 132, 236, 0.05);
  border: 1px solid rgba(155, 132, 236, 0.1);
  border-radius: 6px;
  font-size: 0.65rem;
  color: var(--accent-purple);
  z-index: 2;
  opacity: 0.8;
}

.historical {
  z-index: 1;
}

/* Hover effects */
.overview-grid:hover::before {
  border-color: var(--accent-purple);
  background: rgba(155, 132, 236, 0.05);
  opacity: 0.8;
}

.overview-grid:hover::after {
  border-color: rgba(155, 132, 236, 0.2);
  background: rgba(155, 132, 236, 0.03);
  opacity: 0.6;
}

.stat-value {
  font-size: 28px;
  font-weight: 700;
  color: var(--discord-light);
  line-height: 1;
}

.stat-label {
  font-size: 13px;
  color: var(--discord-gray);
  margin-bottom: 2px;
}

.stat-trend {
  font-size: 0.8rem;
  color: var(--discord-gray);
  margin-top: 0.15rem;
  display: flex;
  justify-content: center;
  gap: 3px;
  align-items: center;
  text-align: center;
  width: 100%;
}

/* For nested spans within stat-trend */
.stat-trend .positive {
  color: var(--success-green);
  background: rgba(59, 165, 92, 0.1);
  padding: 2px 6px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: fit-content;
}

.stat-trend .negative {
  color: var(--error-red);
  background: rgba(237, 66, 69, 0.1);
  padding: 2px 6px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: fit-content;
}

/* For direct class combinations */
.stat-trend.positive {
  color: var(--success-green);
  background: rgba(59, 165, 92, 0.1);
  padding: 2px 6px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: fit-content;
  margin: 0 auto;
}

.stat-trend.negative {
  color: var(--error-red);
  background: rgba(237, 66, 69, 0.1);
  padding: 2px 6px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: fit-content;
  margin: 0 auto;
}

.heatmap-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.heatmap-grid {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  gap: 4px;
  width: 100%;
}

.heatmap-cell {
  aspect-ratio: 1/1;
  min-height: 30px;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  transition: all 0.15s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.heatmap-cell.peak-hour {
  border: 2px solid rgba(255, 215, 0, 0.7);
  box-shadow: 0 0 8px rgba(255, 215, 0, 0.3);
  z-index: 2;
}

.heatmap-cell.quietest-hour {
  border: 2px solid rgba(100, 149, 237, 0.7);
  box-shadow: 0 0 8px rgba(100, 149, 237, 0.3);
  z-index: 1;
}

.peak-indicator {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
}

.heatmap-cell:hover {
  transform: translateY(-4px) scale(1.05);
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.hour-block {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  transition: all 0.2s ease;
}

.hour-block:hover {
  background: rgba(255, 255, 255, 0.04);
}

.hour-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  min-width: 180px;
}

.hour-range {
  font-size: 0.9rem;
  color: var(--discord-light);
  font-weight: 600;
}

.hour-activity {
  font-size: 0.8rem;
  color: var(--discord-gray);
}

.activity-bar {
  flex: 1;
  height: 12px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  overflow: hidden;
}

.activity-fill {
  height: 100%;
  background: linear-gradient(90deg, var(--accent-purple) 0%, var(--accent-blue) 100%);
  border-radius: 6px;
  transition: all 0.3s ease;
}

.engagement-rate {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--discord-light);
  min-width: 100px;
  text-align: right;
}

.channels-grid {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
}

.active-channels-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  margin-top: 1rem;
}

.channel-card {
  background: var(--glass-bg);
  border: 1px solid var(--glass-border);
  border-radius: 12px;
  padding: 1.5rem;
  transition: all 0.2s ease;
  flex: 1;
  min-width: calc(20% - 1rem); /* For 5 cards max */
  max-width: 100%;
}

/* Responsive widths based on number of cards */
.active-channels-container:has(.channel-card:only-child) .channel-card {
  flex-basis: 100%;
}

.active-channels-container:has(.channel-card:nth-child(2):last-child) .channel-card {
  flex-basis: calc(50% - 0.5rem);
}

.active-channels-container:has(.channel-card:nth-child(3):last-child) .channel-card {
  flex-basis: calc(33.333% - 0.667rem);
}

.active-channels-container:has(.channel-card:nth-child(4):last-child) .channel-card {
  flex-basis: calc(25% - 0.75rem);
}

.active-channels-container:has(.channel-card:nth-child(5):last-child) .channel-card {
  flex-basis: calc(20% - 0.8rem);
}

.channel-card:hover {
  transform: translateY(-2px);
  border-color: var(--accent-purple);
  background: rgba(155, 132, 236, 0.05);
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .channel-card {
    min-width: calc(33.333% - 1rem);
  }
}

@media (max-width: 768px) {
  .channel-card {
    min-width: calc(50% - 1rem);
  }
}

@media (max-width: 480px) {
  .channel-card {
    min-width: 100%;
  }
}

.channel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.channel-name {
  font-weight: 500;
  color: var(--discord-light);
}

.channel-trend {
  font-size: 0.875rem;
  padding: 0.25rem 0.75rem;
  border-radius: 6px;
}

.channel-trend.positive {
  color: var(--success-green);
  background: rgba(59, 165, 92, 0.1);
}

.channel-trend.negative {
  color: var(--error-red);
  background: rgba(237, 66, 69, 0.1);
}

.channel-stats {
  margin-top: 0.5rem;
}

.stat-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.group-value {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--discord-light);
}

.group-label {
  font-size: 0.875rem;
  color: var(--discord-gray);
}

.activity-indicator {
  height: 4px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  overflow: hidden;
}

.activity-bar {
  height: 100%;
  background: linear-gradient(90deg, var(--accent-purple), var(--accent-blue));
  border-radius: 2px;
  transition: width 0.3s ease;
}

.performance-metrics {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 650px;
  overflow-y: auto;
}

.performance-metrics h4 {
  font-size: 16px;
  font-weight: 600;
  color: var(--discord-light);
  margin-bottom: 16px;
}

.metric-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid var(--glass-border);
  border-radius: 12px;
  margin-bottom: 16px;
}

.metric-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  padding: 16px;
  background: var(--glass-bg);
  border: 1px solid var(--glass-border);
  border-radius: 8px;
  transition: all 0.3s ease;
}

.metric-item:hover {
  border-color: var(--accent-purple);
  transform: translateY(-2px);
}

.metric-value {
  font-size: 28px;
  font-weight: 700;
  color: var(--discord-light);
  background: linear-gradient(135deg, var(--accent-purple) 0%, var(--accent-blue) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.metric-label {
  font-size: 14px;
  color: var(--discord-gray);
}

.accuracy-badge {
  padding: 6px 16px;
  background: rgba(59, 165, 92, 0.1);
  color: var(--success-green);
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 6px;
}

.accuracy-badge::before {
  content: "✓";
  font-size: 16px;
}

.top-queries {
  margin-top: 24px;
}

.query-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid var(--glass-border);
  border-radius: 12px;
  margin-bottom: 8px;
  transition: all 0.3s ease;
}

.query-item:hover {
  background: rgba(255, 255, 255, 0.05);
  border-color: var(--accent-purple);
  transform: translateX(4px);
}

.query-info {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.query-text {
  font-weight: 600;
  color: var(--discord-light);
  font-size: 15px;
}

.query-metrics {
  display: flex;
  gap: 16px;
  font-size: 13px;
  color: var(--discord-gray);
}

.accuracy {
  color: var(--success-green);
  display: flex;
  align-items: center;
  gap: 4px;
}

.accuracy::before {
  content: "●";
  font-size: 10px;
}

.query-trend {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--success-green);
  font-weight: 500;
}

.trend-arrow {
  font-size: 18px;
  line-height: 1;
  transform: translateY(-1px);
}

.risk-metrics {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.risk-overview {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.risk-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-radius: 12px;
  text-align: center;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid var(--glass-border);
}

.risk-item.warning {
  border-color: var(--warning-yellow);
}

.risk-item.danger {
  border-color: var(--error-red);
}

.risk-item.success {
  border-color: var(--success-green);
}

.risk-value {
  font-size: 24px;
  font-weight: 600;
  color: var(--discord-light);
}

.risk-label {
  font-size: 14px;
  color: var(--discord-gray);
}

.risk-trend {
  font-size: 12px;
  font-weight: 500;
}

.risk-trend.positive {
  color: var(--success-green);
}

.risk-trend.negative {
  color: var(--error-red);
}

.alert-count {
  padding: 4px 12px;
  background: rgba(237, 66, 69, 0.1);
  color: var(--error-red);
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
}

.view-all-button {
  padding: 6px 12px;
  background: var(--glass-bg);
  border: 1px solid var(--glass-border);
  border-radius: 8px;
  color: var(--discord-light);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.view-all-button:hover {
  background: rgba(155, 132, 236, 0.1);
  border-color: var(--accent-purple);
}

.time-indicator {
  font-size: 14px;
  color: var(--discord-gray);
}

.stat-header {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}

.info-tooltip {
  color: var(--discord-gray);
  font-size: 14px;
  cursor: help;
  transition: all 0.2s ease;
  background: rgba(129, 140, 248, 0.1);
  color: var(--accent-purple);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 500;
}

.info-tooltip:hover {
  background: rgba(129, 140, 248, 0.2);
  transform: scale(1.1);
}

[title] {
  position: relative;
}

[title]:hover::after {
  content: attr(title);
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #18191c;
  color: #fff;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 10;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 8px;
  pointer-events: none;
}

@media (max-width: 1400px) {
  .analytics-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .analytics-card.wide {
    grid-column: span 1;
  }
  
  .analytics-card.stacked {
    grid-column: span 1;
  }
  
  .channels-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .overview-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1024px) {
  .overview-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .channels-grid {
    flex-direction: column;
  }
  
  .channel-card {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .analytics-grid {
    grid-template-columns: 1fr;
  }
  
  .time-range-select {
    width: 100%;
  }
  
  .overview-grid {
    grid-template-columns: 1fr;
  }
  
  .risk-overview {
    grid-template-columns: 1fr;
  }
  
  .metric-row {
    grid-template-columns: 1fr;
  }
  
  .activity-summary {
    grid-template-columns: 1fr;
  }

  .hour-metrics {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
  }
  
  .github-style-heatmap {
    overflow-x: auto;
  }
  
  .heatmap-container {
    min-width: 600px;
  }
  
  .heatmap-cell {
    min-height: 25px;
  }
}

/* Spinner styles moved to globals.css */

.loading-card {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
}

.overview-grid.loading {
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
}

.activity-summary {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
}

.activity-summary:hover {
  background: rgba(255, 255, 255, 0.04);
  border-color: rgba(155, 132, 236, 0.3);
  transform: translateY(-2px);
}

.summary-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
  padding: 1rem;
  background: rgba(30, 33, 36, 0.3);
  border-radius: 8px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.03);
}

.summary-stat:hover {
  background: rgba(30, 33, 36, 0.5);
  transform: translateY(-2px);
  border-color: rgba(155, 132, 236, 0.2);
}

.summary-icon {
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
  background: rgba(155, 132, 236, 0.1);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid rgba(155, 132, 236, 0.2);
}

.summary-value {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--discord-light);
}

.summary-label {
  font-size: 0.9rem;
  color: var(--discord-gray);
}

.stat-detail {
  font-size: 0.8rem;
  color: var(--accent-purple);
  background: rgba(129, 140, 248, 0.1);
  padding: 0.25rem 0.5rem;
  border-radius: 12px;
}

.hour-metrics {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.hour-engagement {
  font-size: 0.8rem;
  color: var(--accent-purple);
  background: rgba(129, 140, 248, 0.1);
  padding: 0.25rem 0.5rem;
  border-radius: 12px;
}

.no-data {
  text-align: center;
  padding: 2rem;
  color: var(--discord-gray);
  background: rgba(255, 255, 255, 0.02);
  border-radius: 12px;
  font-size: 0.9rem;
}

.sentiment-metrics {
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.sentiment-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 140px;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
}

.sentiment-overview {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  height: 100%;
  width: 100%;
  align-items: flex-end;
  min-height: 400px;
}

.sentiment-chart {
  width: 100%;
  height: 400px;
  padding: 40px 60px 77px 60px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
  background: rgba(30, 33, 36, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
}

/* Grid lines */
.sentiment-chart::before {
  content: '';
  position: absolute;
  top: 40px;
  left: 60px;
  right: 20px;
  bottom: 77px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.03) 1px, 
    transparent 1px, transparent 100%
  );
  background-size: 100% 10%;
  background-position: 0 0;
  pointer-events: none;
  z-index: 0;
}

/* Percentage labels container */
.percentage-labels {
  position: absolute;
  top: 40px;
  left: 0;
  bottom: 77px;
  width: 60px;
  display: grid;
  grid-template-rows: repeat(11, 1fr);
  pointer-events: none;
  z-index: 1;
}

/* Individual percentage label */
.percentage-label {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.4);
  text-align: right;
  padding-right: 12px;
  font-family: monospace;
  letter-spacing: -0.5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* Columns container */
.sentiment-columns {
  position: absolute;
  top: 40px;
  left: 60px;
  right: 20px;
  bottom: 77px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  z-index: 2;
  height: calc(100% - 117px);
}

.column-bar-container {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}

.column-bar {
  width: 40px !important;
  max-width: 100px;
  transition: all 0.3s ease;
  border-radius: 4px 4px 0 0;
  min-width: 40px;
  position: absolute;
  bottom: 0;
  cursor: pointer;
  transform-origin: bottom;
  left: 50%;
  transform: translateX(-50%);
}

.sentiment-label {
  position: absolute;
  text-align: center;
  font-size: 12px;
  white-space: nowrap;
  width: 100%;
  bottom: -60px;
  left: 0;
  color: rgba(255, 255, 255, 0.8);
}

.sentiment-footer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  background: rgba(30, 33, 36, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  height: 400px;
}

.trend-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 16px;
  background: rgba(45, 48, 52, 0.5);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.top-topics {
  flex: 1;
  overflow-y: auto;
  padding-right: 10px;
}

.sentiment-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: relative;
}

.sentiment-bar-container {
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: 1rem;
  align-items: center;
  margin-bottom: 0.5rem;
}

.sentiment-bar-label {
  font-size: 0.85rem;
  color: var(--discord-light);
  text-align: right;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sentiment-bar-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
  min-width: 0;
}

.sentiment-bar {
  height: 24px!important;
  border-radius: 12px;
  transition: width 0.6s ease;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
}

.sentiment-bar.positive, 
.sentiment-bar.very-positive { 
  background: linear-gradient(90deg, rgba(76, 175, 80, 0.8) 0%, rgba(76, 175, 80, 0.4) 100%);
}

.sentiment-bar.neutral { 
  background: linear-gradient(90deg, rgba(158, 158, 158, 0.8) 0%, rgba(158, 158, 158, 0.4) 100%);
}

.sentiment-bar.negative, 
.sentiment-bar.very-negative { 
  background: linear-gradient(90deg, rgba(244, 67, 54, 0.8) 0%, rgba(244, 67, 54, 0.4) 100%);
}

.sentiment-bar.somewhat-negative { 
  background: linear-gradient(90deg, rgba(255, 152, 0, 0.8) 0%, rgba(255, 152, 0, 0.4) 100%);
}

.sentiment-bar-stats {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.85rem;
  color: var(--discord-light);
  background: rgba(0, 0, 0, 0.2);
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  backdrop-filter: blur(4px);
}

.sentiment-percentage {
  font-weight: 600;
}

.sentiment-count {
  color: var(--discord-gray);
  font-size: 0.75rem;
}

@media (max-width: 1200px) {
  .sentiment-overview {
    grid-template-columns: 1fr;
  }
}

.divider {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 1.5rem 0;
}

/* GitHub-style heatmap */
.github-style-heatmap {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 1rem;
  background: rgba(30, 33, 36, 0.3);
  border-radius: 12px;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.heatmap-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.heatmap-header h4 {
  font-size: 16px;
  font-weight: 600;
  color: var(--discord-light);
  margin: 0;
}

.heatmap-legend {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.legend-label {
  font-size: 12px;
  color: var(--discord-gray);
}

.legend-cells {
  display: flex;
  gap: 3px;
}

.legend-cell {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.heatmap-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.heatmap-hours {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  gap: 4px;
  padding: 0 0 8px 0;
}

.hour-label {
  font-size: 11px;
  color: var(--discord-gray);
  text-align: center;
}

.heatmap-cell:hover {
  transform: translateY(-4px) scale(1.05);
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.heatmap-cell:hover .cell-tooltip {
  display: block;
}

.cell-tooltip {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 8px 12px;
  width: max-content;
  z-index: 100;
  margin-bottom: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.cell-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.9) transparent transparent transparent;
}

.tooltip-time {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 4px;
  color: var(--discord-light);
}

.tooltip-count {
  font-size: 12px;
  color: var(--discord-gray);
}

.tooltip-emojis {
  font-size: 12px;
  color: var(--discord-gray);
  margin-top: 2px;
}

/* Activity level colors - GitHub style */
.activity-level-0 {
  background-color: rgba(22, 27, 34, 0.3);
}

.activity-level-1 {
  background-color: rgba(88, 166, 255, 0.2);
}

.activity-level-2 {
  background-color: rgba(88, 166, 255, 0.4);
}

.activity-level-3 {
  background-color: rgba(88, 166, 255, 0.7);
}

.activity-level-4 {
  background-color: rgba(88, 166, 255, 1);
}

/* Alternative color scheme - Discord style */
/*
.activity-level-0 {
  background-color: rgba(30, 33, 36, 0.3);
}

.activity-level-1 {
  background-color: rgba(155, 132, 236, 0.3);
}

.activity-level-2 {
  background-color: rgba(155, 132, 236, 0.5);
}

.activity-level-3 {
  background-color: rgba(155, 132, 236, 0.7);
}

.activity-level-4 {
  background-color: rgba(155, 132, 236, 1);
}
*/

@media (max-width: 768px) {
  .github-style-heatmap {
    overflow-x: auto;
  }
  
  .heatmap-container {
    min-width: 600px;
  }
  
  .heatmap-cell {
    min-height: 25px;
  }
}

/* Knowledge Gaps Section */
.knowledge-gaps {
  margin-top: 24px;
}

.gaps-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
}

.gap-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: rgba(255, 255, 255, 0.03);
  border-left: 4px solid;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.gap-item.high {
  border-left-color: var(--error-red);
}

.gap-item.medium {
  border-left-color: var(--warning-yellow);
}

.gap-item.low {
  border-left-color: var(--success-green);
}

.gap-item:hover {
  background: rgba(255, 255, 255, 0.05);
  transform: translateX(4px);
}

.gap-info {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.gap-topic {
  font-weight: 600;
  color: var(--discord-light);
  font-size: 15px;
}

.gap-metrics {
  display: flex;
  gap: 16px;
  font-size: 13px;
  color: var(--discord-gray);
}

.gap-impact {
  display: flex;
  align-items: center;
  gap: 4px;
}

.gap-priority {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.gap-priority.high {
  background: rgba(237, 66, 69, 0.1);
  color: var(--error-red);
}

.gap-priority.medium {
  background: rgba(250, 166, 26, 0.1);
  color: var(--warning-yellow);
}

.gap-priority.low {
  background: rgba(59, 165, 92, 0.1);
  color: var(--success-green);
}

.gap-action {
  display: flex;
  align-items: center;
}

.gap-action-btn {
  padding: 6px 12px;
  background: var(--glass-bg);
  border: 1px solid var(--glass-border);
  border-radius: 6px;
  color: var(--discord-light);
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.gap-action-btn:hover {
  background: rgba(155, 132, 236, 0.1);
  border-color: var(--accent-purple);
}

/* Q&A Improvement Suggestions */
.suggestion-count {
  padding: 4px 12px;
  background: rgba(155, 132, 236, 0.1);
  color: var(--accent-purple);
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
}

.suggestion-metrics {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.suggestion-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
}

.suggestion-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  transition: all 0.3s ease;
}

.suggestion-item:hover {
  background: rgba(255, 255, 255, 0.05);
  transform: translateX(4px);
}

.suggestion-icon {
  font-size: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(155, 132, 236, 0.1);
  border-radius: 8px;
}

.suggestion-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
}

.suggestion-title {
  font-weight: 600;
  color: var(--discord-light);
  font-size: 14px;
}

.suggestion-description {
  font-size: 12px;
  color: var(--discord-gray);
}

.suggestion-action {
  display: flex;
  align-items: center;
}

.suggestion-btn {
  padding: 6px 12px;
  background: var(--glass-bg);
  border: 1px solid var(--glass-border);
  border-radius: 6px;
  color: var(--discord-light);
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.suggestion-btn:hover {
  background: rgba(155, 132, 236, 0.1);
  border-color: var(--accent-purple);
}

/* Message Threads Styles */
.thread-metrics {
  height: 650px;
  overflow-y: auto;
  padding: 0.5rem;
}

.thread-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.thread-item {
  position: relative;
  padding: 16px 20px 20px;
  border-radius: 12px;
  background: rgba(30, 33, 36, 0.95);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.08);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 8px;
}

.thread-content {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  position: relative;
  z-index: 2;
  padding-bottom: 8px;
}

.thread-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.thread-title {
  font-weight: 600;
  color: #FFFFFF;
  font-size: 15px;
  line-height: 1.5;
  letter-spacing: 0.01em;
  flex: 1;
}

.thread-meta {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);
}

.thread-date {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.02em;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.9);
  background: rgba(255, 255, 255, 0.1);
}

.thread-channel {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.02em;
  white-space: nowrap;
  color: var(--accent-purple);
  background: rgba(155, 132, 236, 0.15);
}

.thread-sentiment {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.02em;
  white-space: nowrap;
  text-transform: capitalize;
}

.thread-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 0 0 12px 12px;
  overflow: hidden;
}

.thread-progress-bar {
  height: 100%;
  background: linear-gradient(90deg, 
    rgba(155, 132, 236, 0.8), 
    rgba(115, 103, 240, 0.8)
  );
  transition: width 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.thread-progress-label {
  position: absolute;
  right: 20px;
  bottom: 24px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.9);
  background: rgba(0, 0, 0, 0.25);
  padding: 4px 10px;
  border-radius: 4px;
  z-index: 2;
  backdrop-filter: blur(5px);
  font-weight: 500;
  letter-spacing: 0.02em;
}

/* Thread sentiment colors */
.thread-item.neutral { 
  border-left: 4px solid rgba(158, 158, 158, 0.5);
}
.thread-item.positive { 
  border-left: 4px solid rgba(76, 175, 80, 0.5);
}
.thread-item.very.positive { 
  border-left: 4px solid rgba(46, 125, 50, 0.5);
}
.thread-item.negative { 
  border-left: 4px solid rgba(244, 67, 54, 0.5);
}
.thread-item.somewhat.negative { 
  border-left: 4px solid rgba(255, 152, 0, 0.5);
}

.thread-item:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.12);
  background: rgba(45, 48, 52, 0.98);
}

.thread-sentiment.neutral { 
  background: rgba(158, 158, 158, 0.2);
  color: #E0E0E0;
}
.thread-sentiment.positive { 
  background: rgba(76, 175, 80, 0.2);
  color: #81C784;
}
.thread-sentiment.very.positive { 
  background: rgba(46, 125, 50, 0.2);
  color: #66BB6A;
}
.thread-sentiment.negative { 
  background: rgba(244, 67, 54, 0.2);
  color: #E57373;
}
.thread-sentiment.somewhat.negative { 
  background: rgba(255, 152, 0, 0.2);
  color: #FFB74D;
}

/* Channel Filter Enhancement */
.channel-filter {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
  padding: 16px;
  background: linear-gradient(135deg,
    rgba(30, 33, 36, 0.95),
    rgba(30, 33, 36, 0.85)
  );
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(10px);
}

.channel-filter label {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  letter-spacing: 0.02em;
}

.channel-filter select {
  flex: 1;
  padding: 10px 16px;
  border-radius: 8px;
  background: rgba(47, 49, 54, 0.6);
  border: 1px solid rgba(79, 84, 92, 0.3);
  color: #FFFFFF;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23B9BBBE%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 10px auto;
  padding-right: 40px;
  font-weight: 500;
  letter-spacing: 0.02em;
  backdrop-filter: blur(5px);
}

.channel-filter select:hover {
  border-color: rgba(155, 132, 236, 0.5);
  background-color: rgba(47, 49, 54, 0.8);
}

.channel-filter select:focus {
  outline: none;
  border-color: var(--accent-purple);
  box-shadow: 0 0 0 2px rgba(155, 132, 236, 0.25);
  background-color: rgba(47, 49, 54, 0.8);
}

/* Enhanced scrollbar styling */
.thread-metrics {
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 132, 236, 0.3) rgba(0, 0, 0, 0.2);
  padding: 4px 16px;
}

.thread-metrics::-webkit-scrollbar {
  width: 8px;
}

.thread-metrics::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.thread-metrics::-webkit-scrollbar-thumb {
  background: rgba(155, 132, 236, 0.3);
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.thread-metrics::-webkit-scrollbar-thumb:hover {
  background: rgba(155, 132, 236, 0.5);
}

.trend-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.trend-icon {
  font-size: 1.25rem;
  background: rgba(155, 132, 236, 0.1);
  padding: 0.5rem;
  border-radius: 8px;
  color: var(--accent-purple);
}

.trend-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
}

.trend-text {
  font-size: 1rem;
  color: var(--discord-light);
  font-weight: 600;
}

.trend-insight {
  font-size: 0.9rem;
  color: var(--discord-gray);
  line-height: 1.4;
}

.trend-metadata {
  display: flex;
  gap: 12px;
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.metadata-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.metadata-label {
  font-size: 0.75rem;
  color: var(--discord-gray);
}

.metadata-value {
  font-size: 0.85rem;
  color: var(--discord-light);
  font-weight: 500;
}

.topic-item {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  padding: 1rem;
  background: rgba(45, 48, 52, 0.5);
  border-radius: 8px;
  transition: all 0.2s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.topic-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
  min-width: 0;
}

.topic-name {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--discord-light);
}

.topic-summary {
  font-size: 0.8rem;
  color: var(--discord-gray);
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.topic-metadata {
  display: flex;
  gap: 12px;
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  font-size: 0.75rem;
  color: var(--discord-gray);
}

.top-topics {
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 132, 236, 0.3) transparent;
}

.top-topics::-webkit-scrollbar {
  width: 6px;
}

.top-topics::-webkit-scrollbar-track {
  background: transparent;
}

.top-topics::-webkit-scrollbar-thumb {
  background-color: rgba(155, 132, 236, 0.3);
  border-radius: 3px;
}

.topic-item {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 12px;
  border-radius: 6px;
  background: rgba(45, 48, 52, 0.5);
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
}

.topic-item:hover {
  background: rgba(45, 48, 52, 0.8);
}

/* Sentiment Tooltips */
.sentiment-tooltip {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 12px;
  min-width: 220px;
  z-index: 1000;
  margin-bottom: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  pointer-events: none;
}

.column-bar:hover {
  filter: brightness(1.1);
}

.column-bar:hover .sentiment-tooltip {
  display: block;
  opacity: 1;
}

/* Sentiment colors */
.column-bar.positive {
  background-color: #4CAF50;
}

.column-bar.negative {
  background-color: #F44336;
}

.column-bar.neutral {
  background-color: #9E9E9E;
}

.sentiment-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.95) transparent transparent transparent;
}

.tooltip-header {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.tooltip-total {
  font-size: 13px;
  color: #B9BBBE;
  margin-bottom: 12px;
}

.tooltip-channels {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.tooltip-subtitle {
  font-size: 12px;
  color: #72767D;
  margin-bottom: 4px;
}

.tooltip-channel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding: 4px 0;
}

.channel-name {
  color: #B9BBBE;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.channel-stats {
  color: #72767D;
  font-size: 11px;
}

/* Thread sentiment colors */
.thread-item.neutral { 
  border-left: 4px solid #9E9E9E;
  background: rgba(30, 33, 36, 0.95);
}
.thread-item.positive { 
  border-left: 4px solid #4CAF50;
  background: rgba(76, 175, 80, 0.1);
}
.thread-item.very.positive { 
  border-left: 4px solid #2E7D32;
  background: rgba(46, 125, 50, 0.1);
}
.thread-item.negative { 
  border-left: 4px solid #f44336;
  background: rgba(244, 67, 54, 0.1);
}
.thread-item.somewhat.negative { 
  border-left: 4px solid #FF9800;
  background: rgba(255, 152, 0, 0.1);
}

.thread-item.neutral:hover { background: rgba(45, 48, 52, 0.98); }
.thread-item.positive:hover { background: rgba(76, 175, 80, 0.15); }
.thread-item.very.positive:hover { background: rgba(46, 125, 50, 0.15); }
.thread-item.negative:hover { background: rgba(244, 67, 54, 0.15); }
.thread-item.somewhat.negative:hover { background: rgba(255, 152, 0, 0.15); }

.thread-sentiment.neutral { 
  background: rgba(158, 158, 158, 0.2);
  color: #E0E0E0;
}
.thread-sentiment.positive { 
  background: rgba(76, 175, 80, 0.2);
  color: #81C784;
}
.thread-sentiment.very.positive { 
  background: rgba(46, 125, 50, 0.2);
  color: #66BB6A;
}
.thread-sentiment.negative { 
  background: rgba(244, 67, 54, 0.2);
  color: #E57373;
}
.thread-sentiment.somewhat.negative { 
  background: rgba(255, 152, 0, 0.2);
  color: #FFB74D;
} 