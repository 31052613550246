.word-cloud-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
  background: #2D3034;
  border-radius: 8px;
  overflow: hidden;
}

.word-cloud {
  width: 100%;
  height: 100%;
  cursor: grab;
}

.word-cloud:active {
  cursor: grabbing;
}

.info-panel {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 250px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.panel-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.panel-header button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 0 4px;
  color: #666;
}

.panel-header button:hover {
  color: #000;
}

.panel-content {
  padding: 16px;
}

.panel-content p {
  margin: 8px 0;
  font-size: 14px;
  color: #666;
}

.word-cloud-tooltip {
  display: none;
  position: fixed;
  background: rgba(32, 34, 37, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 14px;
  color: #fff;
  z-index: 1000;
  pointer-events: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.tooltip-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.tooltip-content strong {
  color: #fff;
}

.tooltip-content span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
}

.tooltip-content .category {
  color: #7289DA;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.tooltip-hint {
  margin-top: 4px;
  font-style: italic;
  color: #818cf8 !important;
}

.tooltip-hint.inactive {
  color: rgba(255, 255, 255, 0.5) !important;
}

.cloud-controls {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  gap: 8px;
  z-index: 10;
}

.reset-button,
.zoom-controls button {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: rgba(255, 255, 255, 0.8);
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transition: all 0.2s ease;
}

.reset-button:hover,
.zoom-controls button:hover {
  background: rgba(255, 255, 255, 0.2);
  color: white;
}

.zoom-controls {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.drag-hint {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(32, 34, 37, 0.9);
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  pointer-events: none;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  white-space: nowrap;
}

.word-cloud-container:hover .drag-hint {
  opacity: 0;
}

.related-words-panel {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(32, 34, 37, 0.95);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1rem;
  transform: translateY(100%);
  animation: slideUp 0.3s ease forwards;
  max-height: 60%;
  overflow-y: auto;
}

@keyframes slideUp {
  to {
    transform: translateY(0);
  }
}

.panel-title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex: 1;
  min-width: 0;
}

.panel-title h3 {
  margin: 0;
  font-size: 1.1rem;
  color: #fff;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.category-badge {
  padding: 4px 8px;
  background: rgba(114, 137, 218, 0.2);
  border-radius: 4px;
  font-size: 0.8rem;
  color: #7289DA;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

.close-button {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: 24px;
  line-height: 1;
  padding: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  margin: -4px;
}

.close-button:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.related-words-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.word-details {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem 1rem;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
}

.detail-label {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}

.detail-value {
  color: #fff;
  font-weight: 500;
}

.related-words-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.related-words-list h4 {
  margin: 0;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
}

.related-word {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  transition: background 0.2s ease;
}

.related-word:hover {
  background: rgba(255, 255, 255, 0.1);
}

.related-text {
  color: #fff;
  font-size: 0.9rem;
}

.related-count {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.1);
  padding: 2px 8px;
  border-radius: 10px;
}

.no-related {
  color: rgba(255, 255, 255, 0.5);
  font-style: italic;
  text-align: center;
  padding: 1rem;
  margin: 0;
}

/* Scrollbar styling */
.related-words-panel::-webkit-scrollbar {
  width: 6px;
}

.related-words-panel::-webkit-scrollbar-track {
  background: transparent;
}

.related-words-panel::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

.related-words-panel::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.2);
} 