.tab-navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 72px;
  background: linear-gradient(to right, #0f0f13, #1a1a24);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4), 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(10px);
}

.tab-container {
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.nav-left, .nav-center, .nav-right {
  display: flex;
  align-items: center;
}

.nav-center {
  flex: 1;
  justify-content: center;
  gap: 1.5rem;
}

.server-select-wrapper {
  position: relative;
  display: inline-block;
}

.server-select-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  pointer-events: none;
  opacity: 0.8;
  transition: all 0.3s ease;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.3));
}

.server-select-wrapper:hover .server-select-icon {
  opacity: 1;
  transform: translateY(-50%) scale(1.1);
}

/* Style for the status indicator and server name */
.status-indicator {
  display: inline-block;
  width: 16px;
  text-align: center;
  margin-right: 6px;
}

.server-name {
  display: inline-block;
  padding-left: 2px;
}

.server-select {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.04));
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 12px;
  color: #fff;
  padding: 0.3rem 2.8rem 0.7rem 2.5rem;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  min-width: 220px;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23a5affb' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.2rem center;
  background-size: 1em;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2), inset 0 1px 1px rgba(255, 255, 255, 0.1);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  letter-spacing: 0.02em;
}

.server-select:hover {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.06));
  border-color: rgba(255, 255, 255, 0.25);
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(255, 255, 255, 0.15);
  color: #ffffff;
}

.server-select:focus {
  outline: none;
  border-color: #818cf8;
  box-shadow: 0 0 0 3px rgba(129, 140, 248, 0.3), 0 6px 16px rgba(0, 0, 0, 0.2);
  background: linear-gradient(to bottom, rgba(129, 140, 248, 0.15), rgba(129, 140, 248, 0.08));
}

.server-select option {
  padding: 8px 12px;
  background-color: #1a1a24;
  color: #fff;
  font-weight: 500;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

/* Add custom styling for the dropdown container (works in modern browsers) */
.server-select::-ms-expand {
  display: none;
}

/* Style for Firefox */
.server-select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

/* Enhance dropdown with a custom dropdown indicator animation */
.server-select:hover + .dropdown-indicator,
.server-select:focus + .dropdown-indicator {
  transform: translateY(2px);
}

/* Add a subtle animation to the server select on page load */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.server-select {
  animation: fadeInUp 0.5s ease-out forwards;
}

/* Add a subtle pulse effect to the dropdown arrow on hover */
@keyframes pulse {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

.server-select:hover::after {
  animation: pulse 1.5s infinite;
}

.tab-item {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding: 0.75rem 1.4rem;
  border: none;
  border-radius: 10px;
  background: transparent;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  position: relative;
  overflow: hidden;
}

.tab-item:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.03));
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 10px;
}

.tab-item:hover {
  color: #fff;
  transform: translateY(-1px);
}

.tab-item:hover:before {
  opacity: 1;
}

.tab-item.active {
  background: linear-gradient(135deg, rgba(129, 140, 248, 0.2), rgba(129, 140, 248, 0.1));
  color: #a5affb;
  box-shadow: 0 2px 10px rgba(129, 140, 248, 0.15);
}

.tab-item.active:before {
  opacity: 0;
}

.tab-icon {
  font-size: 1.2rem;
  filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.3));
}

.tab-label {
  font-size: 1rem;
  letter-spacing: 0.02em;
}

.tab-item.logout {
  color: rgba(255, 89, 89, 0.8);
}

.tab-item.logout:hover {
  color: #ff5959;
  background: rgba(255, 89, 89, 0.08);
}

@media (max-width: 1024px) {
  .tab-container {
    padding: 0 10px;
  }

  .nav-center {
    gap: 0.8rem;
  }

  .tab-item {
    padding: 0.75rem 1.2rem;
  }
}

@media (max-width: 768px) {
  .tab-label {
    display: none;
  }
  
  .tab-item {
    padding: 12px;
  }
  
  .tab-icon {
    font-size: 1.4rem;
  }

  .server-select {
    min-width: 150px;
    max-width: 200px;
  }
}

@media (max-width: 480px) {
  .tab-container {
    flex-wrap: wrap;
    height: auto;
    gap: 1rem;
  }

  .nav-left {
    width: 100%;
    order: -1;
  }

  .server-select {
    width: 100%;
    min-width: 100%;
  }

  .nav-center {
    width: 100%;
    justify-content: space-between;
  }

  .nav-right {
    width: 100%;
    justify-content: center;
  }

  .tab-navigation {
    height: auto;
    padding: 0.5rem;
  }
} 