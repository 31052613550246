.dashboard-container {
  padding: 2rem;
  color: #E4E6EB;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.welcome-section h1 {
  font-size: 1.75rem;
  margin: 0;
  font-weight: 600;
  background: linear-gradient(to right, #fff, #a5b4fc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.welcome-section p {
  color: #A0A4B8;
  margin: 0.5rem 0 0 0;
}

.quick-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.date-range-selector {
  display: flex;
  background: rgba(255, 255, 255, 0.05);
  padding: 0.25rem;
  border-radius: 8px;
  gap: 0.25rem;
}

.range-button {
  padding: 0.5rem 1rem;
  border: none;
  background: transparent;
  color: #A0A4B8;
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.range-button:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

.range-button.active {
  background: rgba(129, 140, 248, 0.2);
  color: #818cf8;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.action-button:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-1px);
}

.logout-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: 8px;
  background: rgba(255, 89, 89, 0.15);
  color: #ff5959;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.logout-button:hover {
  background: rgba(255, 89, 89, 0.25);
  transform: translateY(-1px);
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.stat-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 1.5rem;
  transition: transform 0.2s ease;
}

.stat-card:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.08);
}

.stat-card h3 {
  color: #A0A4B8;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0 0 0.75rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.stat-value {
  font-size: 2rem;
  font-weight: 600;
  margin: 0.5rem 0;
  background: linear-gradient(45deg, #a5b4fc, #818cf8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stat-label {
  color: #A0A4B8;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  font-weight: 400;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
}

.activity-feed, .insights-panel {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 1.5rem;
}

.activity-feed h2, .insights-panel h2 {
  font-size: 1.25rem;
  margin: 0 0 1.5rem 0;
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.activity-feed h2::before {
  content: '📱';
  font-size: 1.4rem;
}

.insights-panel h2::before {
  content: '✨';
  font-size: 1.4rem;
}

.activity-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.activity-item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  transition: background 0.2s ease;
}

.activity-item:hover {
  background: rgba(255, 255, 255, 0.05);
}

.activity-icon {
  font-size: 1.25rem;
  min-width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.activity-content {
  flex: 1;
}

.activity-content p {
  margin: 0;
  line-height: 1.4;
  font-size: 0.95rem;
}

.activity-content strong {
  color: #fff;
  font-weight: 500;
}

.activity-content .channel {
  color: #818cf8;
  font-weight: 500;
}

.activity-content .channel.voice {
  color: #3ba55c;
}

.activity-content .channel.text {
  color: #818cf8;
}

.activity-content .query {
  display: block;
  margin-top: 4px;
  font-style: italic;
  color: #A0A4B8;
  font-size: 0.9em;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.timestamp {
  font-size: 0.8rem;
  color: #A0A4B8;
  display: block;
  margin-top: 0.75rem;
  font-weight: 400;
}

.insights-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.insight-card {
  background: rgba(45, 48, 52, 0.5);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.insight-card h3 {
  margin: 0 0 1rem 0;
  font-size: 1rem;
  font-weight: 600;
  color: var(--discord-light);
}

.topic-cloud {
  height: 300px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.insight-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.insight-card li {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.02);
  margin-bottom: 0.5rem;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.insight-card li:hover {
  background: rgba(255, 255, 255, 0.05);
}

.insight-card .count {
  background: rgba(129, 140, 248, 0.1);
  color: #818cf8;
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-size: 0.85rem;
  font-weight: 500;
}

.topic-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.topic-tag {
  background: rgba(129, 140, 248, 0.1);
  color: var(--accent-purple);
  padding: 0.5rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
  border: 1px solid rgba(129, 140, 248, 0.1);
}

.topic-tag:hover {
  background: rgba(129, 140, 248, 0.15);
  transform: translateY(-1px);
  border-color: rgba(129, 140, 248, 0.2);
}

.topic-category {
  color: var(--accent-purple);
  font-weight: 600;
}

.topic-specific {
  color: var(--discord-light);
  opacity: 0.9;
}

.topic-count {
  background: rgba(129, 140, 248, 0.2);
  padding: 0.2rem 0.4rem;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--accent-purple);
  margin-left: 0.25rem;
}

.no-topics {
  color: var(--discord-gray);
  font-size: 0.875rem;
  font-style: italic;
}

.server-selection {
  margin-top: 0.5rem;
}

.server-select {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: #fff;
  padding: 0.5rem 1rem;
  padding-left: 35px!important;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 200px;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  padding-right: 2.5rem;
}

.server-select:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.server-select:focus {
  outline: none;
  border-color: #818cf8;
  box-shadow: 0 0 0 2px rgba(129, 140, 248, 0.2);
}

.server-select option {
  background-color: #1a1a1a;
  color: #fff;
  padding: 0.5rem;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 900px) {
  .dashboard-grid {
    grid-template-columns: 1fr;
  }
  
  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .header-actions {
    width: 100%;
    flex-direction: column;
    gap: 1rem;
  }
  
  .quick-actions {
    width: 100%;
    justify-content: flex-start;
    overflow-x: auto;
    padding-bottom: 0.5rem;
  }

  .logout-button {
    width: 100%;
    justify-content: center;
  }
}

/* Section titles styling */
.section-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #fff;
  margin: 0 0 1rem 0;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.section-title::before {
  font-size: 1.4rem;
}

.activity-feed h2, .insights-panel h2 {
  font-size: 1.25rem;
  margin: 0 0 1.5rem 0;
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.activity-feed h2::before {
  content: '📱';
  font-size: 1.4rem;
}

.insights-panel h2::before {
  content: '✨';
  font-size: 1.4rem;
}

/* Stats cards styling */
.stat-card h3 {
  color: #A0A4B8;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0 0 0.75rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.stat-value {
  font-size: 2rem;
  font-weight: 600;
  margin: 0.5rem 0;
  background: linear-gradient(45deg, #a5b4fc, #818cf8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stat-label {
  color: #A0A4B8;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  font-weight: 400;
}

/* Insight cards styling */
.insight-card h3 {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 0 1.25rem 0;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.insight-card h3::before {
  font-size: 1.3rem;
}

/* Activity items styling */
.activity-content p {
  margin: 0;
  line-height: 1.4;
  font-size: 0.95rem;
}

.activity-content strong {
  color: #fff;
  font-weight: 500;
}

.activity-content .channel {
  color: #818cf8;
  font-weight: 500;
}

.activity-content .channel.voice {
  color: #3ba55c;
}

.activity-content .channel.text {
  color: #818cf8;
}

.timestamp {
  font-size: 0.8rem;
  color: #A0A4B8;
  display: block;
  margin-top: 0.75rem;
  font-weight: 400;
}

.loading-card {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 12px;
  border: 1px solid var(--glass-border);
}

.stats-grid .loading-card {
  min-height: 120px;
}

.activity-feed .loading-card,
.insights-panel .loading-card {
  min-height: 300px;
}

.activity-item.voice_join,
.activity-item.voice_leave,
.activity-item.voice_move {
  background: rgba(59, 165, 92, 0.05);
}

.activity-item.voice_join:hover,
.activity-item.voice_leave:hover,
.activity-item.voice_move:hover {
  background: rgba(59, 165, 92, 0.1);
}

.activity-item.voice_join .activity-icon,
.activity-item.voice_leave .activity-icon,
.activity-item.voice_move .activity-icon {
  background: rgba(59, 165, 92, 0.1);
  color: #3ba55c;
}

.activity-item.text_channel_create,
.activity-item.voice_channel_create {
  background: rgba(88, 101, 242, 0.05);
}

.activity-item.text_channel_create:hover,
.activity-item.voice_channel_create:hover {
  background: rgba(88, 101, 242, 0.1);
}

.activity-item.text_channel_create .activity-icon,
.activity-item.voice_channel_create .activity-icon {
  background: rgba(88, 101, 242, 0.1);
  color: #5865f2;
}

.activity-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  font-size: 0.85rem;
}

.user-name {
  color: #7289DA;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

/* If the discord_name is a hash (long string), style it differently */
.user-name:not(:empty):not([class*=" "]):not([class*="-"]):not([class*="_"]):not([class*="."]):not([class*="@"]) {
  background-color: rgba(114, 137, 218, 0.1);
  color: #7289DA;
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 0.8rem;
}

.timestamp {
  color: #99AAB5;
  font-size: 0.85rem;
  white-space: nowrap;
}

.activity-item .activity-content p {
  margin-bottom: 2px;
} 