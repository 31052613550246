.thread-panel {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 400px;
  background: #1a1b26;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  animation: slideIn 0.3s ease-out;
  overflow: hidden;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.thread-panel-header {
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1a1b26;
}

.thread-panel-header h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #fff;
  font-weight: 600;
}

.close-button {
  background: none;
  border: none;
  color: #a0a4b8;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  transition: color 0.2s;
}

.close-button:hover {
  color: #fff;
}

.thread-panel-content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.thread-info {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.thread-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.thread-date {
  color: #a0a4b8;
  font-size: 0.9rem;
}

.thread-sentiment {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
}

.thread-sentiment.positive {
  background: rgba(76, 175, 80, 0.2);
  color: #4caf50;
}

.thread-sentiment.negative {
  background: rgba(244, 67, 54, 0.2);
  color: #f44336;
}

.thread-sentiment.neutral {
  background: rgba(158, 158, 158, 0.2);
  color: #9e9e9e;
}

.message-count {
  color: #a0a4b8;
  font-size: 0.9rem;
}

.messages-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.message-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.message-time {
  color: #a0a4b8;
  font-size: 0.8rem;
}

.message-content {
  color: #fff;
  font-size: 0.95rem;
  line-height: 1.4;
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
} 