.rules {
  padding: 24px;
  background: var(--discord-dark);
}

.rules-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.rules-header h2 {
  font-size: 28px;
  font-weight: 600;
  background: linear-gradient(135deg, var(--discord-light) 0%, var(--discord-gray) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.save-button {
  padding: 10px 20px;
  background: var(--accent-purple);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.save-button:hover {
  background: var(--accent-blue);
  transform: translateY(-1px);
}

.rules-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.rules-card {
  background: var(--glass-bg);
  border: 1px solid var(--glass-border);
  border-radius: 16px;
  padding: 24px;
  transition: all 0.3s ease;
}

.rules-card:hover {
  border-color: var(--accent-purple);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.rules-card h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--discord-light);
  margin-bottom: 24px;
}

.settings-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.setting-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.setting-item.full-width {
  flex-direction: column;
  align-items: stretch;
}

.setting-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.setting-label {
  font-weight: 500;
  color: var(--discord-light);
}

.setting-description {
  font-size: 12px;
  color: var(--discord-gray);
}

.setting-select {
  min-width: 180px;
  padding: 8px 12px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--glass-border);
  border-radius: 6px;
  color: var(--discord-light);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.setting-select:hover {
  border-color: var(--accent-purple);
  background: rgba(155, 132, 236, 0.1);
}

.setting-input {
  width: 100%;
  padding: 8px 12px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--glass-border);
  border-radius: 6px;
  color: var(--discord-light);
  font-size: 14px;
  transition: all 0.2s ease;
}

.setting-input:hover {
  border-color: var(--accent-purple);
  background: rgba(155, 132, 236, 0.1);
}

.setting-textarea {
  width: 100%;
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--glass-border);
  border-radius: 6px;
  color: var(--discord-light);
  font-size: 14px;
  resize: vertical;
  min-height: 100px;
  transition: all 0.2s ease;
}

.setting-textarea:hover {
  border-color: var(--accent-purple);
  background: rgba(155, 132, 236, 0.1);
}

.range-input-container {
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 180px;
}

input[type="range"] {
  flex: 1;
  height: 4px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  appearance: none;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 16px;
  height: 16px;
  background: var(--accent-purple);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}

input[type="range"]::-webkit-slider-thumb:hover {
  transform: scale(1.2);
  background: var(--accent-blue);
}

.range-value {
  min-width: 48px;
  text-align: right;
  font-size: 14px;
  color: var(--discord-light);
  font-weight: 500;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.1);
  transition: .4s;
  border-radius: 24px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: var(--accent-purple);
}

input:checked + .toggle-slider:before {
  transform: translateX(20px);
}

@media (max-width: 1200px) {
  .rules-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .rules-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .save-button {
    width: 100%;
  }

  .setting-item {
    flex-direction: column;
    align-items: stretch;
  }

  .setting-select,
  .range-input-container {
    width: 100%;
    min-width: unset;
  }
} 