:root {
    --discord-blue: #5865F2;
    --discord-dark: #0A0A0B;
    --discord-darker: #050506;
    --discord-light: #ffffff;
    --discord-gray: #B9BBBE;
    --glow-purple: rgba(155, 132, 236, 0.2);
    --blur-size: 120px;
    
    /* New variables */
    --accent-purple: #9B84EC;
    --accent-blue: #5865F2;
    --glass-bg: rgba(255, 255, 255, 0.03);
    --glass-border: rgba(255, 255, 255, 0.05);
    --success-green: #3BA55C;
    --warning-yellow: #FAA61A;
    --error-red: #ED4245;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@property --gradient-angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
}

body {
    font-family: 'Inter', sans-serif;
    background: var(--discord-dark);
    color: var(--discord-light);
    line-height: 1.6;
    overflow-x: hidden;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* Buttons */
.primary-button {
    background: linear-gradient(
        45deg,
        var(--accent-purple),
        var(--accent-blue)
    );
    color: white;
    padding: 14px 28px;
    border-radius: 12px;
    text-decoration: none;
    font-weight: 550;
    transition: all 0.3s ease;
    border: none;
    position: relative;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.primary-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        45deg,
        transparent,
        rgba(255, 255, 255, 0.2),
        transparent
    );
    transform: translateX(-100%);
    transition: transform 0.6s;
}

.primary-button:hover::before {
    transform: translateX(100%);
}

.primary-button .icon {
    font-size: 1.2em;
    line-height: 1;
    transform: translateY(-1px);
}

.secondary-button {
    background: var(--glass-bg);
    color: white;
    padding: 14px 28px;
    border-radius: 12px;
    text-decoration: none;
    font-weight: 600;
    border: 1px solid var(--glass-border);
    transition: all 0.3s ease;
    backdrop-filter: blur(10px);
    display: inline-flex;
    align-items: center;
    gap: 8px;
}

.secondary-button:hover {
    border-color: var(--accent-purple);
    background: rgba(155, 132, 236, 0.1);
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    line-height: 1.2;
}

h1 {
    font-size: 4rem;
    margin-bottom: 1.5rem;
}

h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

h3 {
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
}

p {
    margin-bottom: 1rem;
}

/* Sections */
section {
    padding: 80px 0;
}

/* Cards */
.card {
    background: var(--glass-bg);
    border: 1px solid var(--glass-border);
    border-radius: 16px;
    padding: 24px;
    transition: all 0.3s ease;
}

.card:hover {
    transform: translateY(-4px);
    border-color: var(--accent-purple);
}

/* Grid */
.grid {
    display: grid;
    gap: 24px;
}

.grid-2 {
    grid-template-columns: repeat(2, 1fr);
}

.grid-3 {
    grid-template-columns: repeat(3, 1fr);
}

.grid-4 {
    grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 1024px) {
    .grid-4 {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    h1 {
        font-size: 3rem;
    }
    
    h2 {
        font-size: 2rem;
    }
    
    .grid-2, .grid-3 {
        grid-template-columns: 1fr;
    }
    
    .grid-4 {
        grid-template-columns: 1fr;
    }
}

/* Utilities */
.text-gradient {
    background: linear-gradient(
        120deg,
        var(--accent-purple),
        var(--accent-blue)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.bg-gradient {
    background: linear-gradient(
        45deg,
        var(--accent-purple),
        var(--accent-blue)
    );
}

/* Animation */
@keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0px); }
}

@keyframes gradient-rotate {
    0% { --gradient-angle: 0deg; }
    100% { --gradient-angle: 360deg; }
}

/* Gradient Orb Animation */
.gradient-orb {
    position: absolute;
    border-radius: 50%;
    filter: blur(var(--blur-size));
    opacity: 0.5;
    pointer-events: none;
    transition: all 0.5s ease;
    z-index: 0;
}

.orb-1 {
    background: var(--discord-blue);
    width: 500px;
    height: 500px;
    top: -250px;
    left: -250px;
}

.orb-2 {
    background: var(--glow-purple);
    width: 700px;
    height: 700px;
    top: 30%;
    right: -350px;
}

/* Noise Texture */
.noise {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.03;
    pointer-events: none;
    z-index: 9999;
    background: repeating-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.1) 0px,
        rgba(255, 255, 255, 0.1) 1px,
        transparent 1px,
        transparent 2px
    );
}

/* Components */
.demo-card {
    background: var(--glass-bg);
    border: 1px solid var(--glass-border);
    border-radius: 16px;
    overflow: hidden;
}

.demo-header {
    background: rgba(0, 0, 0, 0.2);
    padding: 12px;
    border-bottom: 1px solid var(--glass-border);
}

.demo-dots {
    display: flex;
    gap: 6px;
}

.demo-dots span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
}

.demo-dots span:nth-child(1) { background: #ED4245; }
.demo-dots span:nth-child(2) { background: #FAA61A; }
.demo-dots span:nth-child(3) { background: #3BA55C; }

.demo-content {
    padding: 20px;
}

.chat-message {
    margin-bottom: 20px;
}

.chat-message .user {
    color: var(--accent-purple);
    font-weight: 600;
    margin-bottom: 4px;
    display: block;
}

.chat-message.bot {
    background: rgba(155, 132, 236, 0.1);
    border-radius: 12px;
    padding: 16px;
}

.bot-tag {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    color: var(--accent-blue);
    font-weight: 600;
    margin-bottom: 8px;
}

.response-card {
    background: var(--glass-bg);
    border: 1px solid var(--glass-border);
    border-radius: 8px;
    padding: 12px;
    margin-top: 8px;
}

.context {
    display: block;
    color: var(--accent-purple);
    font-size: 0.9rem;
    margin-top: 8px;
    cursor: pointer;
}

/* Footer */
.footer {
    background: var(--discord-darker);
    padding: 80px 0 40px;
    margin-top: 80px;
}

.footer-grid {
    display: grid;
    grid-template-columns: 2fr repeat(4, 1fr);
    gap: 40px;
}

.footer-section h4 {
    color: var(--discord-light);
    margin-bottom: 20px;
}

.footer-section a {
    color: var(--discord-gray);
    text-decoration: none;
    display: block;
    margin-bottom: 12px;
    transition: color 0.3s ease;
}

.footer-section a:hover {
    color: var(--discord-light);
}

.social-links {
    display: flex;
    gap: 16px;
    margin-top: 20px;
}

.social-links a {
    color: var(--discord-gray);
    transition: color 0.3s ease;
}

.social-links a:hover {
    color: var(--discord-light);
}

.footer-bottom {
    margin-top: 60px;
    padding-top: 20px;
    border-top: 1px solid var(--glass-border);
    text-align: center;
    color: var(--discord-gray);
}

@media (max-width: 1024px) {
    .footer-grid {
        grid-template-columns: repeat(3, 1fr);
    }
    
    .footer-section:first-child {
        grid-column: 1 / -1;
    }
}

@media (max-width: 768px) {
    .footer-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .footer-grid {
        grid-template-columns: 1fr;
    }
}

/* Smooth Scroll */
html {
    scroll-behavior: smooth;
}

/* Parallax Container */
.parallax-wrapper {
    position: relative;
    overflow: hidden;
}

/* Mouse Trail Effect */
.mouse-trail {
    position: fixed;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    pointer-events: none;
    z-index: 10000;
    mix-blend-mode: screen;
    background: radial-gradient(circle, var(--discord-blue) 0%, transparent 70%);
    opacity: 0;
    transition: opacity 0.3s ease;
}

/* Intersection Observer Animations */
.fade-up {
    opacity: 0;
    transform: translateY(30px);
    transition: all 0.8s ease;
}

.fade-up.visible {
    opacity: 1;
    transform: translateY(0);
}

.stagger-children > * {
    opacity: 0;
    transform: translateY(30px);
    transition: all 0.8s ease;
}

.stagger-children.visible > * {
    opacity: 1;
    transform: translateY(0);
}

.stagger-children.visible > *:nth-child(2) {
    transition-delay: 0.1s;
}

.stagger-children.visible > *:nth-child(3) {
    transition-delay: 0.2s;
}

/* Unified Spinner */
.app-spinner {
    width: 48px;
    height: 48px;
    border: 4px solid rgba(155, 132, 236, 0.2);
    border-radius: 50%;
    border-top-color: #6c5ce7;
    animation: spin 0.8s linear infinite;
    margin: 0 auto;
}

/* Smaller spinner variant */
.app-spinner.small {
    width: 24px;
    height: 24px;
    border-width: 3px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
} 