.tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.welcome-section h1 {
  font-size: 1.75rem;
  margin: 0;
  font-weight: 600;
  background: linear-gradient(to right, #fff, #a5b4fc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.quick-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.date-range-selector {
  display: flex;
  background: rgba(255, 255, 255, 0.05);
  padding: 0.25rem;
  border-radius: 8px;
  gap: 0.25rem;
}

.range-button {
  padding: 0.5rem 1rem;
  border: none;
  background: transparent;
  color: #A0A4B8;
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.range-button:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

.range-button.active {
  background: rgba(129, 140, 248, 0.2);
  color: #818cf8;
}

.insights-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #818cf8 0%, #4f46e5 100%);
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.insights-button:hover {
  transform: translateY(-1px);
  filter: brightness(1.1);
}

@media (max-width: 900px) {
  .tab-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .quick-actions {
    width: 100%;
    justify-content: flex-start;
    overflow-x: auto;
    padding-bottom: 0.5rem;
  }
} 