.upload-status-window {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 420px;
  max-height: 500px;
  background-color: #121212;
  border-radius: 10px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border: 1px solid rgba(255, 255, 255, 0.08);
  color: #e0e0e0;
}

.upload-status-window.hidden {
  transform: translateY(120%);
  opacity: 0;
  pointer-events: none;
}

.upload-status-window.minimized {
  height: 56px;
  overflow: hidden;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.upload-status-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  background-color: #1a1a1a;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  cursor: pointer;
  user-select: none;
}

.upload-status-header:hover {
  background-color: #222222;
}

.upload-status-header h3 {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #e0e0e0;
}

.upload-status-header h3 svg {
  color: #a78bfa;
  font-size: 18px;
}

.upload-status-header .badge {
  background-color: #7c3aed;
  color: white;
  border-radius: 12px;
  padding: 2px 8px;
  font-size: 11px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(124, 58, 237, 0.3);
}

.upload-status-header .notification-badge {
  background-color: #ef4444;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 11px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  animation: pulse 1.5s infinite;
  box-shadow: 0 2px 4px rgba(239, 68, 68, 0.3);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(239, 68, 68, 0.4);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(239, 68, 68, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(239, 68, 68, 0);
  }
}

.upload-status-header .controls {
  display: flex;
  gap: 6px;
}

.upload-status-header button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 4px;
  color: #a0a0a0;
  transition: all 0.2s ease;
}

.upload-status-header button:hover {
  background-color: rgba(255, 255, 255, 0.05);
  color: #e0e0e0;
}

.upload-status-header button:active {
  transform: scale(0.95);
}

.upload-status-header .debug-button {
  background-color: #7c3aed;
  color: white;
  font-size: 10px;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: 500;
}

.upload-status-header .debug-button:hover {
  background-color: #6d28d9;
  color: white;
}

.upload-status-content {
  padding: 16px 20px;
  overflow-y: auto;
  max-height: 440px;
  scrollbar-width: thin;
  scrollbar-color: #4b5563 #1a1a1a;
  background-color: #121212;
}

.upload-status-content::-webkit-scrollbar {
  width: 6px;
}

.upload-status-content::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.upload-status-content::-webkit-scrollbar-thumb {
  background-color: #4b5563;
  border-radius: 6px;
}

.upload-item {
  padding: 16px;
  border-radius: 8px;
  background-color: #1a1a1a;
  margin-bottom: 16px;
  border-left: 3px solid #333;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.upload-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}

.upload-item.processing {
  border-left-color: #7c3aed;
}

.upload-item.completed {
  border-left-color: #10b981;
}

.upload-item.error {
  border-left-color: #ef4444;
}

.upload-item-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 12px;
}

.upload-item-details {
  font-size: 13px;
  color: #a0a0a0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.upload-item-name {
  font-weight: 600;
  font-size: 15px;
  color: #e0e0e0;
  margin-bottom: 5px;
  word-break: break-word;
}

.upload-item-status {
  font-size: 12px;
  padding: 4px 10px;
  border-radius: 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  flex-shrink: 0;
}

.upload-item-status.processing {
  background-color: rgba(124, 58, 237, 0.15);
  color: #a78bfa;
}

.upload-item-status.completed {
  background-color: rgba(16, 185, 129, 0.15);
  color: #34d399;
}

.upload-item-status.error {
  background-color: rgba(239, 68, 68, 0.15);
  color: #f87171;
}

.upload-item-meta {
  display: flex;
  gap: 16px;
  margin-top: 0;
}

.upload-item-meta span {
  display: flex;
  align-items: center;
  gap: 4px;
}

.upload-item-channels {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 8px;
}

.upload-item-channel {
  background-color: #2a2a2a;
  padding: 4px 10px;
  border-radius: 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.2s ease;
  color: #d1d5db;
}

.upload-item-channel:hover {
  background-color: #333333;
}

.upload-item-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 8px;
}

.upload-item-tag {
  background-color: rgba(124, 58, 237, 0.15);
  color: #a78bfa;
  padding: 4px 10px;
  border-radius: 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.2s ease;
}

.upload-item-tag:hover {
  background-color: rgba(124, 58, 237, 0.25);
}

.upload-item-error {
  margin-top: 10px;
  color: #f87171;
  font-size: 13px;
  background-color: rgba(239, 68, 68, 0.1);
  padding: 10px 12px;
  border-radius: 6px;
  border-left: 2px solid #ef4444;
}

.upload-item-progress {
  margin-top: 12px;
  height: 5px;
  background-color: #2a2a2a;
  border-radius: 3px;
  overflow: hidden;
}

.upload-item-progress-bar {
  height: 100%;
  background-color: #7c3aed;
  border-radius: 2px;
  transition: width 0.3s ease;
  background-image: linear-gradient(45deg, 
    rgba(255, 255, 255, 0.15) 25%, 
    transparent 25%, 
    transparent 50%, 
    rgba(255, 255, 255, 0.15) 50%, 
    rgba(255, 255, 255, 0.15) 75%, 
    transparent 75%, 
    transparent);
  background-size: 20px 20px;
  animation: progress-bar-stripes 1s linear infinite;
}

.upload-item-time {
  font-size: 12px;
  color: #888;
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.upload-status-empty {
  padding: 30px;
  text-align: center;
  color: #a0a0a0;
}

.upload-status-empty svg {
  margin-bottom: 15px;
  color: #4b5563;
  opacity: 0.7;
  font-size: 32px;
}

@media (max-width: 768px) {
  .upload-status-window {
    width: calc(100% - 40px);
    max-width: 420px;
    bottom: 20px;
    right: 20px;
  }
}

.upload-status-summary {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.upload-status-stat {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  padding: 4px 10px;
  border-radius: 12px;
  font-weight: 500;
  transition: transform 0.2s ease;
}

.upload-status-stat:hover {
  transform: translateY(-1px);
}

.upload-status-stat.processing {
  background-color: rgba(124, 58, 237, 0.15);
  color: #a78bfa;
}

.upload-status-stat.completed {
  background-color: rgba(16, 185, 129, 0.15);
  color: #34d399;
}

.upload-status-stat.error {
  background-color: rgba(239, 68, 68, 0.15);
  color: #f87171;
}

/* Use the unified app-spinner instead of custom spinner */
.upload-item-icon .app-spinner.small {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

/* Remove the duplicate @keyframes spin */ 