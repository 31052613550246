.subscription-selector-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  padding: 2rem;
}

.subscription-selector {
  background: transparent;
  width: 100%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  animation: fadeIn 0.3s ease;
  padding: 0 2rem;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.subscription-header {
  text-align: center;
  margin-bottom: 2rem;
}

.subscription-header h2 {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  background: linear-gradient(135deg, #fff 0%, #818cf8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subscription-header p {
  color: rgba(255, 255, 255, 0.6);
  margin: 0.5rem 0 0 0;
  font-size: 1.1rem;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  transition: all 0.2s ease;
}

.close-button:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  transform: rotate(90deg);
}

/* Loading state */
.subscription-selector-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
}

.loader {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: #818cf8;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Error state */
.subscription-selector-error {
  text-align: center;
  padding: 2rem;
  color: #ff5959;
}

/* Success state */
.subscription-success {
  text-align: center;
  padding: 2rem;
  color: #4ade80;
}

.subscription-success h3 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
}

.subscription-success p {
  color: rgba(74, 222, 128, 0.8);
  margin: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .subscription-selector-modal {
    padding: 1rem;
  }

  .subscription-selector {
    padding: 0;
  }

  .subscription-header h2 {
    font-size: 1.5rem;
  }

  .subscription-header p {
    font-size: 1rem;
  }
}

/* Hide scrollbar but keep functionality */
.subscription-selector {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.subscription-selector::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
} 