.login {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--background-dark);
  position: relative;
  overflow: hidden;
  padding: 25px 24px;
}

.login-container {
  max-width: 1200px;
  width: 100%;
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  align-items: center;
  padding: 0 24px;
}

.login-content {
  max-width: 600px;
}

.login-tag {
  display: inline-block;
  background: rgba(88, 101, 242, 0.1);
  color: var(--primary-color);
  padding: 8px 16px;
  border-radius: 100px;
  font-weight: 500;
  margin-bottom: 24px;
  font-size: 14px;
}

.login h1 {
  font-size: 56px;
  line-height: 1.1;
  margin: 0 0 24px 0;
  font-weight: 700;
  letter-spacing: -0.02em;
}

.login-subtitle {
  font-size: 20px;
  line-height: 1.6;
  color: var(--text-muted);
  margin-bottom: 40px;
  max-width: 500px;
}

.login-card {
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 32px;
  margin-bottom: 48px;
}

.logo-badge {
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.discord-login {
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 12px;
  padding: 16px 32px;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.discord-login:hover {
  background: #4752c4;
  transform: translateY(-1px);
}

.login-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 48px;
  padding-top: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.stat-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.stat-number {
  font-size: 36px;
  font-weight: 700;
  color: var(--primary-color);
  letter-spacing: -0.02em;
}

.stat-label {
  color: var(--text-muted);
  font-size: 14px;
  font-weight: 500;
}

.gradient-orb {
  position: fixed;
  width: 800px;
  height: 800px;
  border-radius: 50%;
  filter: blur(120px);
  opacity: 0.15;
  z-index: 0;
}

.orb-1 {
  background: var(--primary-color);
  top: -400px;
  right: -200px;
}

.orb-2 {
  background: #FF4B91;
  bottom: -400px;
  left: -200px;
}

/* Demo Preview Styles */
.demo-preview {
  position: relative;
}

.demo-card {
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 24px;
}

.demo-header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.demo-dots {
  display: flex;
  gap: 6px;
}

.demo-dots span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
}

.demo-content {
  padding: 24px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

.demo-content h3 {
  font-size: 24px;
  margin: 0 0 8px 0;
}

.demo-subtitle {
  color: var(--text-muted);
  font-size: 14px;
  margin-bottom: 32px;
}

.demo-stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 32px;
}

.demo-stat {
  padding: 16px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
}

.demo-stat-value {
  font-size: 28px;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 4px;
}

.demo-stat-label {
  font-size: 14px;
  color: var(--text-muted);
}

.demo-chart {
  width: 100%;
  height: 200px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  overflow: hidden;
}

.chart-placeholder {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    var(--primary-color) 0%,
    rgba(88, 101, 242, 0.1) 100%
  );
  opacity: 0.5;
}

@media (max-width: 1024px) {
  .login-container {
    grid-template-columns: 1fr;
    gap: 40px;
  }
  
  .demo-preview {
    display: none;
  }
}

@media (max-width: 768px) {
  .login h1 {
    font-size: 40px;
  }
  
  .login-subtitle {
    font-size: 18px;
  }
  
  .login-stats {
    grid-template-columns: 1fr;
    gap: 24px;
  }
}

.float-wrapper {
  display: inline-block;
  animation: float 4s ease-in-out infinite;
}

.emoji-cycle {
  display: inline-block;
  animation: 
    slideDown 0.5s cubic-bezier(0.17, 0.84, 0.44, 1),
    float 4s ease-in-out infinite;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-25px);
  }
  60% {
    transform: translateY(4px);
  }
  75% {
    transform: translateY(-2px);
  }
  85% {
    transform: translateY(1px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes float {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(-1px, -3px);
  }
  40% {
    transform: translate(0.5px, -2px);
  }
  65% {
    transform: translate(1.5px, -1px);
  }
  85% {
    transform: translate(-0.5px, -2.5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

/* Add loading styles */
.login-container.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 300px;
}

.login-container.loading .loader {
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top: 4px solid #7289da;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 1.5rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.login-container.loading p {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.8);
} 