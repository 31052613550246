.document-upload {
  background: var(--discord-dark);
  padding: 2rem;
}

.document-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.document-header h2 {
  color: var(--discord-light);
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

.document-actions {
  position: relative;
}

.upload-button {
  background: var(--accent-purple);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.upload-button:hover {
  background: var(--accent-purple-hover);
  transform: translateY(-1px);
}

.upload-button:disabled {
  background: rgba(155, 132, 236, 0.5);
  cursor: not-allowed;
  transform: none;
}

.document-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.upload-area {
  border: 2px dashed var(--glass-border);
  border-radius: 16px;
  padding: 3rem 2rem;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.03);
  position: relative;
  overflow: hidden;
}

.upload-area::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(155, 132, 236, 0.05) 0%, rgba(155, 132, 236, 0) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.upload-area:hover {
  border-color: var(--accent-purple);
}

.upload-area:hover::before {
  opacity: 1;
}

.upload-area.dragging {
  border-color: var(--accent-purple);
  background: rgba(155, 132, 236, 0.08);
  transform: scale(1.01);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.upload-area.uploading {
  pointer-events: none;
  opacity: 0.7;
}

.upload-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: var(--discord-gray);
  position: relative;
  z-index: 1;
}

.upload-icon {
  font-size: 3.5rem;
  margin-bottom: 0.5rem;
  color: var(--accent-purple);
  background: rgba(155, 132, 236, 0.1);
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.upload-area:hover .upload-icon {
  transform: translateY(-5px);
  background: rgba(155, 132, 236, 0.2);
}

.upload-message h3 {
  color: var(--discord-light);
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.file-select-button {
  background: var(--accent-purple);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-block;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(155, 132, 236, 0.3);
}

.file-select-button:hover {
  background: var(--accent-purple-hover);
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(155, 132, 236, 0.4);
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-formats {
  font-size: 0.85rem;
  opacity: 0.8;
  margin-top: 1rem;
  background: rgba(255, 255, 255, 0.05);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  display: inline-block;
}

.selected-files {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--glass-border);
  border-radius: 16px;
  padding: 1.5rem;
  transition: all 0.3s ease;
}

.selected-files:hover {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.selected-files h3 {
  margin-top: 0;
  margin-bottom: 1.25rem;
  color: var(--discord-light);
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.selected-files h3::before {
  content: '';
  display: block;
  width: 4px;
  height: 20px;
  background: var(--accent-purple);
  border-radius: 2px;
}

.files-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-height: 400px;
  overflow-y: auto;
  padding-right: 0.5rem;
}

.files-list::-webkit-scrollbar {
  width: 6px;
}

.files-list::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 3px;
}

.files-list::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

.files-list::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.2);
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid var(--glass-border);
  border-radius: 12px;
  transition: all 0.2s ease;
}

.file-item:hover {
  background: rgba(255, 255, 255, 0.05);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.file-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.file-icon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(155, 132, 236, 0.1);
  border-radius: 12px;
  color: var(--accent-purple);
  font-size: 1.5rem;
  transition: all 0.2s ease;
}

.file-item:hover .file-icon {
  transform: scale(1.05);
  background: rgba(155, 132, 236, 0.15);
}

.file-preview {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 8px;
}

.file-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
  min-width: 0;
}

.file-name {
  color: var(--discord-light);
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 1rem;
}

.file-meta {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--discord-gray);
  font-size: 0.8rem;
}

.file-size, .file-channels, .file-tags {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background: rgba(255, 255, 255, 0.05);
  padding: 0.25rem 0.5rem;
  border-radius: 12px;
  transition: all 0.2s ease;
}

.file-channels {
  color: #5865F2;
}

.file-tags {
  color: #EB459E;
}

.file-item:hover .file-size, 
.file-item:hover .file-channels, 
.file-item:hover .file-tags {
  background: rgba(255, 255, 255, 0.1);
}

.remove-file {
  background: rgba(255, 255, 255, 0.05);
  border: none;
  color: var(--discord-gray);
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  margin-left: 0.5rem;
}

.remove-file:hover {
  color: var(--error-red);
  background: rgba(237, 66, 69, 0.1);
  transform: rotate(90deg);
}

.remove-file:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
}

.file-nav-button {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--glass-border);
  border-radius: 8px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--discord-light);
  cursor: pointer;
  transition: all 0.2s ease;
  flex-shrink: 0;
}

.file-nav-button:hover:not(:disabled) {
  background: rgba(155, 132, 236, 0.1);
  color: var(--accent-purple);
}

.file-nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.upload-status-message {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
}

.upload-status-message.success {
  background: rgba(59, 165, 92, 0.1);
  color: var(--success-green);
  border: 1px solid rgba(59, 165, 92, 0.2);
}

.upload-status-message.error {
  background: rgba(237, 66, 69, 0.1);
  color: var(--error-red);
  border: 1px solid rgba(237, 66, 69, 0.2);
}

/* File Management Modal Styles */
.file-management-modal {
  background: var(--glass-bg);
  border: 1px solid var(--glass-border);
  border-radius: 12px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.file-management-header {
  margin-bottom: 0.5rem;
}

.file-management-header h2 {
  color: var(--discord-light);
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 0.5rem 0;
}

.file-management-header p {
  color: var(--discord-gray);
  margin: 0;
}

.file-management-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.file-navigation {
  border-bottom: 1px solid var(--glass-border);
  margin-bottom: 1rem;
}

.file-tabs-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
}

.file-tabs-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.file-tabs {
  display: flex;
  gap: 0.5rem;
  overflow-x: auto;
  padding: 0.5rem 0;
  flex: 1;
  scrollbar-width: thin;
  max-width: calc(100% - 100px);
}

.file-tabs::-webkit-scrollbar {
  height: 4px;
}

.file-tabs::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 2px;
}

.file-tabs::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
}

.file-tab {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--glass-border);
  border-radius: 8px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
  transition: all 0.2s ease;
  height: 36px;
}

.file-tab:hover {
  background: rgba(255, 255, 255, 0.08);
}

.file-tab.active {
  background: rgba(155, 132, 236, 0.1);
  border-color: var(--accent-purple);
  position: relative;
}

.file-tab.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--accent-purple);
}

.file-tab-icon {
  color: var(--accent-purple);
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-tab-name {
  color: var(--discord-light);
  font-size: 0.875rem;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remove-file-tab {
  background: none;
  border: none;
  color: var(--discord-gray);
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 50%;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.remove-file-tab:hover {
  color: var(--error-red);
  background: rgba(237, 66, 69, 0.1);
}

.file-nav-buttons {
  display: flex;
  gap: 0.5rem;
  margin-left: 0.5rem;
}

.file-counter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1rem;
}

.file-counter {
  color: var(--discord-light);
  font-size: 0.875rem;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.05);
  padding: 0.5rem 1rem;
  border-radius: 8px;
  min-width: 120px;
  text-align: center;
}

.file-edit-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.file-preview-section {
  display: flex;
  gap: 1.5rem;
  align-items: flex-start;
}

.file-preview-container {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid var(--glass-border);
  border-radius: 8px;
  overflow: hidden;
}

.file-preview-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.file-icon-large {
  font-size: 3rem;
  color: var(--discord-gray);
}

.file-info-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.file-name-edit {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.file-name-edit label {
  color: var(--discord-gray);
  font-size: 0.875rem;
}

.file-name-input {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--glass-border);
  border-radius: 8px;
  padding: 0.75rem;
  color: var(--discord-light);
  font-size: 1rem;
  width: 100%;
}

.file-name-input:focus {
  outline: none;
  border-color: var(--accent-purple);
}

.file-size-info {
  display: flex;
  gap: 1rem;
  color: var(--discord-gray);
  font-size: 0.875rem;
}

.file-tags-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.file-tags-section label {
  color: var(--discord-gray);
  font-size: 0.875rem;
}

.tags-input-container {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--glass-border);
  border-radius: 8px;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag-item {
  background: rgba(155, 132, 236, 0.1);
  border: 1px solid var(--accent-purple);
  border-radius: 16px;
  padding: 0.25rem 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--discord-light);
  font-size: 0.875rem;
}

.remove-tag {
  background: none;
  border: none;
  color: var(--discord-gray);
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 50%;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.remove-tag:hover {
  color: var(--error-red);
}

.tag-input-wrapper {
  width: 100%;
}

.tag-input {
  background: transparent;
  border: none;
  color: var(--discord-light);
  font-size: 0.875rem;
  width: 100%;
  padding: 0.25rem 0;
}

.tag-input:focus {
  outline: none;
}

.file-channels-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.file-channels-section label {
  color: var(--discord-gray);
  font-size: 0.875rem;
}

.channel-search {
  position: relative;
  margin-bottom: 1rem;
}

.search-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--discord-gray);
}

.channel-search-input {
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--glass-border);
  border-radius: 8px;
  color: var(--discord-light);
  font-size: 1rem;
}

.channel-search-input:focus {
  outline: none;
  border-color: var(--accent-purple);
}

.channels-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  max-height: 250px;
  overflow-y: auto;
  padding-right: 0.5rem;
}

.channels-grid::-webkit-scrollbar {
  width: 8px;
}

.channels-grid::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

.channels-grid::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.channels-grid::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.2);
}

.channel-item {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid var(--glass-border);
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.channel-item:hover {
  background: rgba(255, 255, 255, 0.05);
  border-color: var(--accent-purple);
}

.channel-item.selected {
  background: rgba(155, 132, 236, 0.1);
  border-color: var(--accent-purple);
}

.channel-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.channel-name {
  color: var(--discord-light);
  font-weight: 500;
}

.channel-topic {
  color: var(--discord-gray);
  font-size: 0.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.channel-select-indicator {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--accent-purple);
}

.no-channels-message {
  grid-column: 1 / -1;
  text-align: center;
  padding: 2rem;
  color: var(--discord-gray);
}

.file-management-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.cancel-button {
  background: rgba(255, 255, 255, 0.05);
  color: var(--discord-light);
  border: 1px solid var(--glass-border);
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cancel-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.finish-button {
  background: var(--accent-purple);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.finish-button:hover {
  background: var(--accent-purple-hover);
}

.finish-button:disabled {
  background: rgba(155, 132, 236, 0.5);
  cursor: not-allowed;
}

.upload-progress-text {
  white-space: nowrap;
}

.upload-progress-bar {
  width: 100px;
  height: 6px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  overflow: hidden;
}

.upload-progress-fill {
  height: 100%;
  background: white;
  border-radius: 3px;
  transition: width 0.3s ease;
}

.file-summary {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--glass-border);
}

.file-summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.03);
  padding: 0.75rem 1rem;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.file-summary-item:hover {
  background: rgba(255, 255, 255, 0.05);
}

.file-summary-item span:first-child {
  color: var(--discord-gray);
  font-weight: 500;
}

.file-summary-item span:last-child {
  color: var(--discord-light);
  font-weight: 600;
}

.loading-stats {
  padding: 1rem;
  text-align: center;
  color: var(--discord-gray);
  font-style: italic;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
}

.file-stats-section {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid var(--glass-border);
  border-radius: 12px;
  padding: 1.25rem;
  margin-top: 0.5rem;
}

.file-stats-section h4 {
  margin: 0 0 1rem 0;
  color: var(--discord-light);
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.file-stats-section h4::before {
  content: '';
  display: block;
  width: 3px;
  height: 16px;
  background: var(--accent-purple);
  border-radius: 1.5px;
}

.file-stats-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-height: 200px;
  overflow-y: auto;
  padding-right: 0.5rem;
}

.file-stats-list::-webkit-scrollbar {
  width: 4px;
}

.file-stats-list::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 2px;
}

.file-stats-list::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
}

.file-stat-item {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 0.75rem;
  transition: all 0.2s ease;
}

.file-stat-item:hover {
  background: rgba(255, 255, 255, 0.08);
  transform: translateY(-2px);
}

.file-stat-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.file-stat-name {
  color: var(--discord-light);
  font-weight: 500;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.file-stat-name svg {
  color: var(--accent-purple);
  flex-shrink: 0;
}

.file-stat-details {
  display: flex;
  gap: 1rem;
  color: var(--discord-gray);
  font-size: 0.8rem;
}

.file-stat-type, .file-stat-size, .file-stat-channels {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  padding: 0.25rem 0.5rem;
  border-radius: 12px;
  transition: all 0.2s ease;
}

.file-stat-type {
  color: #4CAF50;
}

.file-stat-size {
  color: #2196F3;
}

.file-stat-channels {
  color: #5865F2;
}

.file-stat-item:hover .file-stat-type,
.file-stat-item:hover .file-stat-size,
.file-stat-item:hover .file-stat-channels {
  background: rgba(255, 255, 255, 0.1);
}

.file-stat-link {
  display: inline-flex;
  align-items: center;
  margin-top: 0.5rem;
  color: var(--accent-purple);
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 500;
  transition: all 0.2s ease;
  background: rgba(155, 132, 236, 0.1);
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
}

.file-stat-link:hover {
  color: var(--accent-purple-hover);
  background: rgba(155, 132, 236, 0.2);
  transform: translateY(-1px);
}

.file-stats-summary {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--glass-border);
}

.stats-summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  padding: 0.5rem 0.75rem;
  border-radius: 8px;
  flex: 1;
  min-width: 200px;
}

.stats-summary-item span:first-child {
  color: var(--discord-gray);
  font-size: 0.8rem;
}

.stats-summary-item span:last-child {
  color: var(--discord-light);
  font-weight: 600;
  font-size: 0.9rem;
}

.no-files-message {
  text-align: center;
  padding: 1.5rem;
  color: var(--discord-gray);
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  border: 1px dashed var(--glass-border);
  font-style: italic;
}

.apply-to-all-toggle {
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
}

.apply-to-all-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--discord-light);
  font-size: 0.875rem;
  cursor: pointer;
}

.apply-to-all-checkbox {
  width: 16px;
  height: 16px;
  accent-color: var(--accent-purple);
}

.apply-all-badge {
  font-size: 0.75rem;
  background: rgba(155, 132, 236, 0.1);
  color: var(--accent-purple);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  margin-left: 0.5rem;
  font-weight: normal;
}

.file-navigation-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .document-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .document-actions {
    width: 100%;
  }
  
  .upload-button {
    width: 100%;
  }
  
  .file-preview-section {
    flex-direction: column;
    align-items: center;
  }
  
  .file-preview-container {
    width: 100px;
    height: 100px;
  }
  
  .channels-grid {
    grid-template-columns: 1fr;
  }
  
  .file-management-actions {
    flex-direction: column;
  }
  
  .cancel-button, .finish-button {
    width: 100%;
  }
}

.upload-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.header-content {
  flex: 1;
}

.status-badges {
  display: flex;
  gap: 8px;
  margin-left: 16px;
}

.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}

.status-badge.checking {
  background-color: #fff9c4;
  color: #856404;
}

.status-badge.online {
  background-color: #e7f7e7;
  color: #2e7d32;
}

.status-badge.offline {
  background-color: #ffebee;
  color: #c62828;
}

/* Remove the old server-status styles or keep them for backward compatibility */
.server-status {
  margin: 15px 0;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  /* Adding display: none to hide any instances that might still be using the old class */
  display: none;
}

/* Ongoing Uploads Section */
.ongoing-uploads-section {
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.ongoing-uploads-section h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

.ongoing-uploads-list {
  max-height: 200px;
  overflow-y: auto;
}

.ongoing-upload-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s;
}

.ongoing-upload-item:last-child {
  border-bottom: none;
}

.ongoing-upload-item:hover {
  background-color: #f1f3f5;
}

.ongoing-upload-item.completed {
  background-color: #e6f7e6;
}

.ongoing-upload-item.error {
  background-color: #ffebee;
}

.upload-item-icon {
  margin-right: 12px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
}

.ongoing-upload-item.completed .upload-item-icon {
  color: #4caf50;
}

.ongoing-upload-item.error .upload-item-icon {
  color: #f44336;
}

.upload-item-details {
  flex: 1;
}

.upload-item-name {
  font-weight: 500;
  margin-bottom: 4px;
  font-size: 14px;
  color: #333;
  word-break: break-word;
}

.upload-item-status {
  font-size: 12px;
  color: #666;
}

.ongoing-upload-item.completed .upload-item-status {
  color: #2e7d32;
}

.ongoing-upload-item.error .upload-item-status {
  color: #c62828;
}

/* Server Status Styles */
.server-status {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 4px;
  margin-bottom: 15px;
  font-size: 14px;
}

.server-status .status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.server-status.checking {
  background-color: #f5f5f5;
}

.server-status.checking .status-indicator {
  background-color: #ffc107;
  animation: pulse 1.5s infinite;
}

.server-status.online {
  background-color: #e8f5e9;
}

.server-status.online .status-indicator {
  background-color: #4caf50;
}

.server-status.offline {
  background-color: #ffebee;
}

.server-status.offline .status-indicator {
  background-color: #f44336;
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.drop-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(155, 132, 236, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  border-radius: 16px;
  animation: fadeIn 0.2s ease;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.drop-message {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 2rem;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: white;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  animation: scaleIn 0.3s ease;
}

@keyframes scaleIn {
  from { transform: scale(0.9); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

.drop-message svg {
  color: white;
  filter: drop-shadow(0 0 8px rgba(155, 132, 236, 0.8));
}

.drop-message span {
  font-size: 1.25rem;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.button-text {
  position: relative;
  z-index: 1;
}

/* Upload Progress Styles for Main Screen */
.upload-progress-container {
  width: 100%;
  background: rgba(155, 132, 236, 0.1);
  border: 1px solid var(--accent-purple);
  border-radius: 12px;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.upload-progress-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: var(--discord-light);
  font-weight: 500;
}

.upload-progress-icon {
  color: var(--accent-purple);
  font-size: 1.5rem;
  animation: pulse 1.5s infinite;
}

.upload-progress-bar-container {
  width: 100%;
  height: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.upload-progress-bar-fill {
  height: 100%;
  background: var(--accent-purple);
  border-radius: 4px;
  transition: width 0.3s ease;
}

.upload-progress-percentage {
  text-align: right;
  font-size: 0.875rem;
  color: var(--discord-light);
  font-weight: 600;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.action-buttons {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.manage-button {
  flex: 1;
  background: rgba(255, 255, 255, 0.08);
  color: var(--discord-light);
  border: 1px solid var(--glass-border);
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage-button:hover {
  background: rgba(255, 255, 255, 0.12);
  transform: translateY(-1px);
}

.manage-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
}

.upload-button {
  flex: 1;
  background: var(--accent-purple);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
} 