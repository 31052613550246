.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(8px);
}

.modal-content {
  background: #1a1a1a;
  border-radius: 16px;
  padding: 2rem;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-content h2 {
  margin: 0;
  font-size: 1.8rem;
  background: linear-gradient(to right, #fff, #a5b4fc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.modal-content p {
  color: #A0A4B8;
  margin: 0.5rem 0 1.5rem 0;
}

.server-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.server-item {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid transparent;
}

.server-item:hover {
  background: rgba(255, 255, 255, 0.08);
  transform: translateY(-1px);
}

.server-item.selected {
  background: rgba(129, 140, 248, 0.1);
  border-color: #818cf8;
}

.server-info h3 {
  margin: 0;
  color: #fff;
  font-size: 1.1rem;
}

.server-info p {
  margin: 0.25rem 0 0 0;
  font-size: 0.9rem;
  color: #A0A4B8;
}

.member-count {
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.25rem 0.5rem;
  border-radius: 12px;
  font-size: 0.8rem;
  color: #A0A4B8;
  margin-top: 0.5rem;
}

.status-icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  color: #A0A4B8;
  font-size: 1.2rem;
  transition: all 0.2s ease;
}

.status-icon.selected {
  background: #818cf8;
  color: #fff;
}

.timezone-selection {
  margin: 2rem 0;
}

.timezone-select {
  width: 100%;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--glass-border);
  border-radius: 8px;
  color: var(--discord-light);
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.timezone-select:hover {
  border-color: var(--accent-purple);
  background: rgba(155, 132, 236, 0.1);
}

.timezone-select:focus {
  outline: none;
  border-color: var(--accent-purple);
  box-shadow: 0 0 0 2px rgba(129, 140, 248, 0.2);
}

.timezone-select optgroup {
  background: var(--discord-dark);
  color: var(--discord-gray);
  font-weight: 600;
  padding: 0.5rem;
}

.timezone-select option {
  background: var(--discord-dark);
  color: var(--discord-light);
  padding: 0.5rem;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.back-button {
  padding: 0.75rem 1.5rem;
  border: 1px solid var(--glass-border);
  background: transparent;
  color: var(--discord-light);
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.back-button:hover {
  background: rgba(255, 255, 255, 0.05);
  border-color: var(--discord-light);
}

.continue-button {
  background: linear-gradient(135deg, #818cf8 0%, #4f46e5 100%);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.continue-button:hover:not(:disabled) {
  transform: translateY(-1px);
  filter: brightness(1.1);
}

.continue-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: rgba(255, 255, 255, 0.1);
} 