.subscription-plans {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  color: #fff;
}

.billing-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 1rem;
  background: rgba(15, 15, 20, 0.6);
  padding: 0.5rem 1rem;
  border-radius: 8px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(114, 137, 218, 0.15);
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.billing-toggle span {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.6);
  transition: all 0.2s ease;
  font-weight: 500;
}

.billing-toggle span.active {
  color: #fff;
  font-weight: 600;
}

/* Switch toggle */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(20, 20, 25, 0.8);
  transition: 0.2s;
  border: 1px solid rgba(114, 137, 218, 0.2);
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background: #7289da;
  transition: 0.2s;
}

input:checked + .slider:before {
  transform: translateX(18px);
}

.slider.round {
  border-radius: 22px;
}

.slider.round:before {
  border-radius: 50%;
}

.plans-container {
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1rem;
}

.plan-card {
  background: rgba(15, 15, 20, 0.7);
  border-radius: 12px;
  padding: 2rem;
  width: 380px;
  position: relative;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  border: 1px solid rgba(30, 30, 40, 0.7);
  backdrop-filter: blur(10px);
}

.plan-card:hover {
  transform: translateY(-4px);
  border-color: rgba(114, 137, 218, 0.3);
}

.plan-card.popular {
  background: linear-gradient(135deg, rgba(20, 20, 30, 0.95), rgba(30, 30, 45, 0.95));
  border: 1px solid rgba(114, 137, 218, 0.25);
  transform: scale(1.02);
}

.plan-card.popular:hover {
  transform: scale(1.02) translateY(-4px);
}

.popular-tag {
  position: absolute;
  top: 12px;
  right: 12px;
  background: #7289da;
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.02em;
}

.plan-header {
  text-align: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
}

.plan-header h3 {
  margin: 0 0 0.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: #ffffff;
}

.price {
  margin: 1rem 0;
}

.price .amount {
  font-size: 2.5rem;
  font-weight: 700;
  color: white;
  letter-spacing: -0.02em;
}

.price .period {
  display: block;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 0.25rem;
}

.plan-header p {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.7);
  margin: 0.75rem 0 0;
  line-height: 1.4;
}

.plan-features {
  list-style-type: none;
  padding: 0;
  margin: 0 0 1.5rem;
}

.plan-features li {
  padding: 0.5rem 0 0.5rem 1.5rem;
  position: relative;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.8);
  transition: color 0.2s ease;
}

.plan-features li::before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #7289da;
  font-weight: bold;
}

.plan-cta {
  display: block;
  width: 100%;
  padding: 0.75rem 0;
  background: #7289da;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 0.75rem;
}

.plan-cta:hover {
  background: #5e77d4;
  transform: translateY(-2px);
}

.plan-cta.secondary {
  background: transparent;
  border: 1px solid rgba(114, 137, 218, 0.3);
  color: #7289da;
}

.plan-cta.secondary:hover {
  background: rgba(114, 137, 218, 0.08);
  border-color: rgba(114, 137, 218, 0.5);
}

.plan-cta.current {
  background: rgba(30, 30, 40, 0.6);
  border: 1px solid rgba(114, 137, 218, 0.2);
  cursor: default;
  color: rgba(255, 255, 255, 0.5);
}

.plan-note {
  text-align: center;
  margin-top: 2rem;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.875rem;
  padding: 1rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.guarantee {
  margin-top: 0.5rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .plans-container {
    flex-direction: column;
    align-items: center;
  }
  
  .plan-card, .plan-card.popular {
    width: 100%;
    max-width: 320px;
    transform: none;
  }
  
  .plan-card.popular {
    order: -1;
    margin-bottom: 1rem;
  }
} 