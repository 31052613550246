.settings {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.settings-header h2 {
  font-size: 1.8rem;
  color: #fff;
  margin: 0;
}

.save-button {
  padding: 0.75rem 1.5rem;
  background: #818cf8;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.save-button:hover {
  background: #6366f1;
  transform: translateY(-1px);
}

.settings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.settings-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.settings-card h3 {
  color: #fff;
  margin: 0 0 1.5rem 0;
  font-size: 1.2rem;
}

.settings-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.setting-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
}

.setting-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.setting-label {
  color: #fff;
  font-weight: 500;
}

.setting-description {
  color: #a0a0a0;
  font-size: 0.9rem;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.1);
  transition: 0.4s;
  border-radius: 34px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: #818cf8;
}

input:checked + .toggle-slider:before {
  transform: translateX(24px);
}

.setting-select {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  min-width: 120px;
  cursor: pointer;
}

.setting-select:focus {
  outline: none;
  border-color: #818cf8;
}

.setting-input {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  min-width: 200px;
}

.setting-input:focus {
  outline: none;
  border-color: #818cf8;
}

.stripe-button {
  padding: 0.5rem 1rem;
  background: #635bff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.stripe-button:hover {
  background: #4b45c6;
}

.stripe-button.connected {
  background: rgba(99, 91, 255, 0.2);
  color: #635bff;
}

.upgrade-button {
  padding: 0.5rem 1rem;
  background: #10b981;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.upgrade-button:hover {
  background: #059669;
}

.discord-button {
  padding: 0.5rem 1rem;
  background: #5865f2;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.discord-button:hover {
  background: #4752c4;
}

.delete-button {
  padding: 0.5rem 1rem;
  background: rgba(239, 68, 68, 0.1);
  color: #ef4444;
  border: 1px solid rgba(239, 68, 68, 0.2);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.delete-button:hover {
  background: rgba(239, 68, 68, 0.2);
  border-color: #ef4444;
}

.logout-button {
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.logout-button:hover {
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.3);
}

@media (max-width: 1024px) {
  .settings-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .settings-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  
  .save-button {
    width: 100%;
  }
  
  .setting-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
  
  .setting-info {
    width: 100%;
  }
  
  .toggle {
    align-self: flex-end;
  }
  
  .setting-select {
    width: 100%;
  }
}

/* Server Selection Styles */
.server-selection-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
}

.server-selection-item {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid transparent;
}

.server-selection-item:hover {
  background: rgba(255, 255, 255, 0.08);
  transform: translateY(-1px);
}

.server-selection-item.selected {
  background: rgba(129, 140, 248, 0.1);
  border-color: #818cf8;
}

.server-info h4 {
  margin: 0;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 500;
}

.server-info p {
  margin: 0.25rem 0 0 0;
  font-size: 0.9rem;
  color: #A0A4B8;
}

.member-count {
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.25rem 0.5rem;
  border-radius: 12px;
  font-size: 0.8rem;
  color: #A0A4B8;
  margin-top: 0.5rem;
}

.server-status {
  display: flex;
  align-items: center;
}

.status-icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  color: #A0A4B8;
  font-size: 1.2rem;
  transition: all 0.2s ease;
}

.status-icon.selected {
  background: #818cf8;
  color: #fff;
}

.no-servers-message {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 1.5rem;
  text-align: center;
}

.no-servers-message p {
  color: #fff;
  margin: 0 0 1rem 0;
}

.no-servers-message ul {
  text-align: left;
  color: #A0A4B8;
  margin: 0 0 1.5rem 0;
  padding-left: 1.5rem;
}

.no-servers-message li {
  margin: 0.5rem 0;
}

/* Server Access Management Styles */
.moderator-list {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem 0;
}

.moderator-list h4 {
  color: #fff;
  margin: 0 0 1rem 0;
  font-size: 1rem;
  font-weight: 500;
}

.moderator-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  margin-bottom: 0.75rem;
  transition: all 0.2s ease;
}

.moderator-item:hover {
  background: rgba(255, 255, 255, 0.08);
}

.moderator-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.moderator-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.moderator-details {
  display: flex;
  flex-direction: column;
}

.moderator-name {
  color: #fff;
  font-weight: 500;
  font-size: 0.9rem;
}

.moderator-role {
  color: #a0a0a0;
  font-size: 0.8rem;
}

.remove-moderator-button {
  padding: 0.4rem 0.75rem;
  background: rgba(239, 68, 68, 0.1);
  color: #ef4444;
  border: 1px solid rgba(239, 68, 68, 0.2);
  border-radius: 6px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.remove-moderator-button:hover {
  background: rgba(239, 68, 68, 0.2);
  border-color: #ef4444;
}

.add-moderator-button {
  width: 100%;
  padding: 0.75rem;
  background: rgba(129, 140, 248, 0.1);
  color: #818cf8;
  border: 1px dashed rgba(129, 140, 248, 0.3);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 1rem;
}

.add-moderator-button:hover {
  background: rgba(129, 140, 248, 0.15);
  border-color: rgba(129, 140, 248, 0.4);
}

.plus-icon {
  font-size: 1.2rem;
  line-height: 1;
}

@media (max-width: 768px) {
  .moderator-item {
    flex-direction: column;
    gap: 0.75rem;
    align-items: flex-start;
  }
  
  .remove-moderator-button {
    width: 100%;
  }
} 