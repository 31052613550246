.document-manager {
  background: var(--discord-dark);
  padding: 2rem;
}

.document-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.document-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.document-actions {
  position: relative;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

/* Document Tabs */
.document-tabs {
  display: flex;
  gap: 0.5rem;
}

.tab-button {
  background: rgba(255, 255, 255, 0.05);
  color: var(--discord-gray);
  border: 1px solid var(--glass-border);
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.tab-button:hover {
  background: rgba(255, 255, 255, 0.08);
  color: var(--discord-light);
}

.tab-button.active {
  background: var(--accent-purple);
  color: white;
  border-color: var(--accent-purple);
}

.document-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  background: var(--glass-bg);
  border: 1px solid var(--glass-border);
  border-radius: 12px;
  padding: 1.5rem;
}

.stat-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
}

.stat-label {
  color: var(--discord-gray);
  font-size: 0.875rem;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--discord-light);
}

.document-upload-zone {
  border: 2px dashed var(--glass-border);
  border-radius: 12px;
  padding: 3rem 1.5rem;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
  background: var(--glass-bg);
}

.document-upload-zone:hover {
  border-color: var(--accent-purple);
  background: rgba(155, 132, 236, 0.05);
}

.document-upload-zone.dragging {
  border-color: var(--accent-purple);
  background: rgba(155, 132, 236, 0.1);
}

.upload-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  color: var(--discord-gray);
}

.upload-icon {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.upload-formats {
  font-size: 0.75rem;
  opacity: 0.7;
}

.recent-uploads {
  background: var(--glass-bg);
  border: 1px solid var(--glass-border);
  border-radius: 12px;
  padding: 1.5rem;
}

.recent-uploads h3 {
  margin-bottom: 1.5rem;
  color: var(--discord-light);
  font-size: 1.125rem;
  font-weight: 600;
}

.uploads-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.upload-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid var(--glass-border);
  border-radius: 8px;
  transition: all 0.3s ease;
}

.upload-item:hover {
  background: rgba(255, 255, 255, 0.05);
  border-color: var(--accent-purple);
}

.upload-info {
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
}

.upload-name {
  font-weight: 500;
  color: var(--discord-light);
}

.upload-meta {
  display: flex;
  gap: 1rem;
  color: var(--discord-gray);
  font-size: 0.75rem;
}

.upload-status {
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.375rem 0.75rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

.upload-status.processed {
  color: var(--success-green);
  background: rgba(59, 165, 92, 0.1);
}

.upload-status.processing {
  color: var(--warning-yellow);
  background: rgba(250, 166, 26, 0.1);
}

.loading {
  text-align: center;
  padding: 2rem;
  color: var(--discord-gray);
  font-style: italic;
}

.no-uploads {
  text-align: center;
  padding: 2rem;
  color: var(--discord-gray);
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .document-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  
  .document-actions {
    width: 100%;
  }
  
  .document-actions button {
    width: 100%;
  }
  
  .document-tabs {
    width: 100%;
  }
  
  .tab-button {
    flex: 1;
    text-align: center;
  }
  
  .document-stats {
    grid-template-columns: 1fr;
  }
  
  .upload-item {
    flex-direction: column;
    gap: 0.75rem;
    align-items: flex-start;
  }
} 